import penIcon from '@/assets/icons/trash.png';
import { Button, ConfirmationDialog } from '@/components/Elements';

import { useDeletePayment } from '../api/deletePayment';

type DeletePaymentProps = {
  activeIndex: number;
  user: any;
};

export const DeletePayment = ({ activeIndex, user }: DeletePaymentProps) => {
  const deletePaymentMutation = useDeletePayment();

  return user !== 'zaposleni' ? (
    <ConfirmationDialog
      isDone={deletePaymentMutation.isSuccess}
      icon="danger"
      title="Brisanje uplate"
      cancelButtonText="Otkaži"
      body="Da li ste sigurni da želite da izbrišete uplatu?"
      triggerButton={
        <button className="flex ml-auto mr-4">
          <img src={penIcon} alt="trash icon" />
        </button>
      }
      confirmButton={
        <Button
          isLoading={deletePaymentMutation.isLoading}
          type="button"
          className="bg-red-600 ml-3"
          onClick={() => deletePaymentMutation.mutate({ activeIndex: activeIndex })}
        >
          Obriši uplatu
        </Button>
      }
    />
  ) : (
    <></>
  );
};
