import { useNutritions } from '../api/getSearchData';

export const AllFilterNutritionistsList = ({
  searchParam,
  setSearchParam,
  isAdmin,
  isPatientsPage,
}: any) => {
  const keyName = isPatientsPage ? 'nutritionist_id' : 'medicalRecord.nutritionist_id';
  let allFilterNutritionistsList;
  const filterNutritionist = searchParam[`${keyName}`];

  if (isAdmin) {
    const nutritionsNameArr: any = [];
    const nutritionsQuery: any = useNutritions();
    if (!nutritionsQuery) return null;

    if (nutritionsQuery.data) {
      filterNutritionist.map((id: any) => {
        nutritionsQuery.data.data.map((data: any) => {
          if (data.id === parseInt(id)) {
            nutritionsNameArr.push({ name: data.name, id: data.id });
          }
        });
      });
    }

    allFilterNutritionistsList = nutritionsNameArr.map((data: any) => {
      return (
        <button
          className="bg-dark-green hover:opacity-20 text-white py-2 px-4 rounded mr-5 mb-5 text-sm"
          key={data.id}
          onClick={() => {
            removeNutritionistFromFilter(data.id);
          }}
        >
          {data.name} <span>X</span>
        </button>
      );
    });
  }

  function removeNutritionistFromFilter(id: any) {
    const newArr = [...searchParam[`${keyName}`]];
    setSearchParam({ ...searchParam, [`${keyName}`]: newArr.filter((e) => e !== `${id}`) });
  }

  return <>{allFilterNutritionistsList}</>;
};
