import axios from 'axios';
import React, { Dispatch, SetStateAction } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { API_URL } from '@/config';
import storage from '@/utils/storage';

const token = storage.getToken();

const removeCalendarEvent = (eventId: string) => {
  return axios.delete(
    `${API_URL}/calendar-events/${eventId}?uuid=${localStorage.getItem('uuid')}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }
  );
};

export const useRemoveCalendarEvent = (
  setModal?: Dispatch<SetStateAction<boolean>>,
  setChangeAppointments?: Dispatch<SetStateAction<boolean>>
) => {
  const queryClient = useQueryClient();
  return useMutation(removeCalendarEvent, {
    onSuccess: () => {
      setModal && setModal(false);
      setChangeAppointments && setChangeAppointments(false);
      queryClient.invalidateQueries('next-exam');
    },
  });
};
