import { useEffect, useState } from 'react';

import addDoc from '@/assets/icons/addDoc.png';
import { Button, ConfirmationDialog, Spinner } from '@/components/Elements';

import TypeSelect from './Select';

export const TypeDocument = ({ setDocumentType, uploadFile, addFileMutation, setTimeForSend, setDateForSend, sendOption, setSendOption }: any) => {
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    setIsDone(false);
  }, [isDone]);

  if (addFileMutation.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <>
      <ConfirmationDialog
        isDone={isDone}
        icon="info"
        title="Izaberite tip dokumenta za otpremanje"
        cancelButtonText="Odustani"
        body={<TypeSelect setDocumentType={setDocumentType} setTimeForSend={setTimeForSend} setDateForSend={setDateForSend} sendOption={sendOption} setSendOption={setSendOption} />}
        triggerButton={
          <div className="shadow-lg rounded w-48 py-[15px] mr-5 bg-green">
            <div className="flex items-center justify-center">
              <img className="w-auto" src={addDoc} alt="users img" />
            </div>
            <div className="flex flex-col justify-center">
              <div className="text-white text-center mt-4">Dodaj dokumenta</div>
            </div>
          </div>
        }
        confirmButton={
          <Button
            type="button"
            className="my-bg-green ml-3"
            onClick={() => (uploadFile(), setIsDone(!isDone))}
          >
            Nastavi otpremanje
          </Button>
        }
      />
    </>
  );
};
