import penIcon from '@/assets/icons/trashBin.png';
import { Button, ConfirmationDialog } from '@/components/Elements';

import { useDeleteFolder } from '../../api/deleteFolder';

type DeleteFolderProps = {
  activeIndex: number;
};

export const DeleteFolder = ({ activeIndex }: DeleteFolderProps) => {
  const deleteFolderMutation = useDeleteFolder();

  return (
    <ConfirmationDialog
      icon="danger"
      title="Brisanje foldera"
      cancelButtonText="Otkaži"
      body="Da li ste sigurni da želite da izbrišete folder?"
      triggerButton={
        <Button className="bg-gray p-2.5 px-4 text-red-light mr-2 ml-4">
          <img className="inline-block pb-1 pr-2" src={penIcon}></img>
          Obriši
        </Button>
      }
      confirmButton={
        <Button
          isLoading={deleteFolderMutation.isLoading}
          type="button"
          className="my-bg-green ml-3"
          onClick={() => deleteFolderMutation.mutate({ activeIndex: activeIndex })}
        >
          Obriši folder
        </Button>
      }
    />
  );
};
