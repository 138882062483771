import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';

const schema = z.object({
  email: z.string().min(1, 'Obavezno polje'),
});

type EmailValue = {
  email: string;
};

type ChangePswFormProps = {
  onSuccess: (e: any) => void;
};

export const ChangePswForm = ({ onSuccess }: ChangePswFormProps) => {
  return (
    <div>
      <Form<EmailValue, typeof schema> onSubmit={(e) => onSuccess(e)} schema={schema}>
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Email"
              placeholder="Email"
              className="bg-gray"
              error={formState.errors['email']}
              registration={register('email')}
            />
            <div>
              <Button type="submit" className="w-full my-bg-green font-normal">
                Pošalji mejl
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          <Link to="../login" className="font-medium text-blue-600 hover:text-blue-500">
            Nazad na login
          </Link>
        </div>
      </div>
    </div>
  );
};
