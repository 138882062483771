import { useDiets } from '../api/getSearchData';

export const Diets = ({ setSearchParam, searchParam, isPatientsPage }: any) => {
  const dietsQuery: any = useDiets();
  const keyName = isPatientsPage ? 'diets.id' : 'medicalRecord.diets.id';

  if (!dietsQuery.data) return null;

  function sendDietId(id: any, checked: any) {
    const newArr = [...searchParam[keyName]];
    newArr.push(id);
    if (checked) {
      setSearchParam({ ...searchParam, [`${keyName}`]: newArr, page: 1 });
    } else {
      setSearchParam({ ...searchParam, [`${keyName}`]: newArr.filter((e) => e !== id) });
    }
  }

  const dietList = dietsQuery.data.data.map((diet: any) => {
    return (
      <li key={diet.id}>
        <label>
          <input
            type="checkbox"
            className="mr-2"
            id={diet.id}
            checked={Object(searchParam[`${keyName}`]).includes(`${diet.id}`)}
            onChange={(e) => {
              sendDietId(e.target.id, e.target.checked);
            }}
          />
          {diet.name}
        </label>
      </li>
    );
  });

  return <>{dietList}</>;
};
