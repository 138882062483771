import { useState } from 'react';
import Select from 'react-select';
import SwitchBtn from './SwitchBtn';

export default function TypeSelect({ setDocumentType, setTimeForSend, setDateForSend, sendOption, setSendOption }: any) {

    const statusOptions = [
        { value: 'jelovnik', label: 'Jelovnik' },
        { value: 'recept', label: 'Recept' },
        { value: 'biohemija', label: 'Biohemija' },
        { value: 'anamneza', label: 'Anamneza' },
        { value: 'izveštaj lekara specijaliste', label: 'Izveštaj lekara specijaliste' },
    ];
    const text = 'Pošalji odmah email obaveštenje';
    const [currentStatusOption, setCurrentStatusOption] = useState('jelovnik');

    return (
        <div>
            <Select
                className="py-2.5 text-black mr-2 w-full"
                defaultValue={statusOptions[0]}
                onChange={(e: any) => {
                    setDocumentType(e.value);
                    setCurrentStatusOption(e.value);
                }
                }
                options={statusOptions}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
            />
            {currentStatusOption === 'jelovnik' && (
                <SwitchBtn setTimeForSend={setTimeForSend} setDateForSend={setDateForSend} text={text} sendOption={sendOption} setSendOption={setSendOption} />
            )}
        </div>
    );
}