import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { NewPatient } from '@/components/Elements/PatientCard/NewPatient';
import { PatientsCard } from '@/components/Elements/PatientCard/PatientsCard';
import { MainLayout } from '@/components/Layout';
import { Patients } from '@/features/patients';
import { Payments } from '@/features/payments';
import { UuidPatients } from '@/features/uuid-patients';
import { lazyImport } from '@/utils/lazyImport';

const { DiscussionsRoutes } = lazyImport(
  () => import('@/features/discussions'),
  'DiscussionsRoutes'
);
const { Dashboard } = lazyImport(() => import('@/features/misc'), 'Dashboard');
const { Profile } = lazyImport(() => import('@/features/users'), 'Profile');
const { Users } = lazyImport(() => import('@/features/users'), 'Users');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: '/discussions/*', element: <DiscussionsRoutes /> },
      { path: '/users', element: <Users /> },
      { path: '/profile', element: <Profile /> },
      { path: '/patients', element: <Patients /> },
      { path: '/patients-card/*', element: <PatientsCard /> },
      { path: '/payments', element: <Payments /> },
      { path: '/new-patient', element: <NewPatient /> },
      { path: '*', element: <Navigate to="patients" /> },
    ],
  },
];
