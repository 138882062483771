import { Tab } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ViewMeasurements } from './components/Measurements/ViewMeasurements';
import { EnterPatientInfo } from './EnterPatientInfo';
import { PatientsDocument } from './PatientsDocument';
import { ViewPatientInfo } from './ViewPatientInfo';
import { ViewPayments } from './ViewPayments';

export const PatientsCard = () => {
  let { state }: any = useLocation();
  var url_string = window.location.href;
  var url = new URL(url_string);
  var name = url.searchParams.get("name");

  var retrievedObject: any = localStorage.getItem(`referrer-${name}`);
  if (state === null) {
    state = JSON.parse(retrievedObject);
  }
  const filesCountFromLS = localStorage.getItem('files_count');
  const [filesCount, setFilesCount] = useState(
    filesCountFromLS ? filesCountFromLS : state?.directory.files_count
  );

  useEffect(() => {
    return () => {
      localStorage.removeItem('files_count');
      localStorage.removeItem('directoryIdForUpload');
    };
  }, []);

  const [patientAdded, setPatientAdded] = useState(false);
  useEffect(() => {
    if (state) setPatientAdded(true);
    else setPatientAdded(false);
  }, []);
  return (
    <div className="flex items-center justify-center w-full ">
      <Tab.Group>
        <div className="flex w-full mInline">
          <Tab.List className={state ? 'tabList' : 'hidden'}>
            {state && (
              <Tab
                className={({ selected }) =>
                  selected
                    ? 'bg-[#6BBF79] outline-none border-[#6BBF79] text-[#FAFAFA] h-[61px] focus:outline-none  text-left tracking-[0.25px] text-[18px] leading-[21px] p-2 rounded-t-xl'
                    : 'bg-[#39433B] border-[#39433B] text-[#FAFAFA] h-[61px]  text-left tracking-[0.25px] text-[18px] leading-[21px] p-2  border-b-[1px] border-[#979393] rounded-t-xl'
                }
              >
                Pregled informacija
              </Tab>
            )}

            <Tab
              className={({ selected }) =>
                selected
                  ? 'bg-[#6BBF79] outline-none border-[#6BBF79] text-[#FAFAFA] h-[61px] focus:outline-none  text-left tracking-[0.25px] text-[18px] leading-[21px] p-2 '
                  : 'bg-[#39433B] border-[#39433B]  text-[#FAFAFA] h-[61px]  text-left tracking-[0.25px] text-[18px] leading-[21px] p-2  border-b-[1px] border-[#979393] '
              }
            >
              Unos informacija
            </Tab>

            {state && (
              <Tab
                disabled={patientAdded ? false : true}
                className={({ selected }) =>
                  selected
                    ? ' bg-[#6BBF79] border-[#6BBF79] text-[#FAFAFA] h-[61px] focus:outline-none  text-left tracking-[0.25px] text-[18px] leading-[21px] p-2'
                    : 'bg-[#39433B] border-[#39433B] text-[#FAFAFA] h-[61px]  text-left tracking-[0.25px] text-[18px] leading-[21px] p-2  border-b-[1px] border-[#979393]'
                }
              >
                Uplate
              </Tab>
            )}
            {state && (
              <Tab
                // disabled={patientAdded ? false : true}
                className={({ selected }) =>
                  selected
                    ? 'bg-[#6BBF79] border-[#6BBF79] text-[#FAFAFA] h-[61px] focus:outline-none  text-left tracking-[0.25px] text-[18px] leading-[21px] p-2'
                    : 'bg-[#39433B] text-[#FAFAFA] h-[61px]  text-left tracking-[0.25px] text-[18px] leading-[21px] p-2 border-b-[1px] border-[#979393]'
                }
              >
                Dokumenta ({filesCount})
              </Tab>
            )}
            {state && (
              <Tab
                className={({ selected }) =>
                  selected
                    ? 'rounded-b-xl bg-[#6BBF79] border-[#6BBF79] text-[#FAFAFA] h-[61px] focus:outline-none  text-left tracking-[0.25px] text-[18px] leading-[21px] p-2'
                    : 'bg-[#39433B] border-[#39433B] rounded-b-xl text-[#FAFAFA] h-[61px]  text-left tracking-[0.25px] text-[18px] leading-[21px] p-2'
                }
              >
                Merenje
              </Tab>
            )}
          </Tab.List>
          <Tab.Panels className="flex flex-col w-[1050px] h-fit ml-8 rounded-xl">
            {state && (
              <Tab.Panel>
                <ViewPatientInfo />
              </Tab.Panel>
            )}
            <Tab.Panel>
              <EnterPatientInfo />
            </Tab.Panel>
            <Tab.Panel>
              <ViewPayments />
            </Tab.Panel>
            <Tab.Panel>
              <PatientsDocument setFilesCount={setFilesCount} />
            </Tab.Panel>
            <Tab.Panel>
              <ViewMeasurements />
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
};
