import { CalendarIcon, DocumentDuplicateIcon, PhoneIcon } from '@heroicons/react/outline';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useAuth } from '@/lib/auth';
import { axios } from '@/lib/axios';

import userCalendar from '../../../assets/icons/userCalendar.png';

const PatientLinks = () => {
  const [user, setUser] = useState('');
  useEffect(() => {
    axios.get(`/users/patient/me?uuid=${localStorage.getItem('uuid')}`).then((res) => {
      setUser(res.data.name);
    });
  }, []);
  const { pathname } = useLocation();

  const activeLink = (link: string) => {
    if (link === '/patient') {
      return 0;
    } else if (link === '/patient/documents') {
      return 1;
    } else if (link === '/patient/contact') {
      return 2;
    }
  };
  const uuid = localStorage.getItem('uuid');
  return (
    <div className="mb-4 flex justify-between">
      <div className="hidden md:flex items-center ">
        <img src={userCalendar} alt="user calendar" />
        <p className=" text-[#4A4A4A] font-medium w-full text-[18px] leading-[21px] tracking-[0.25px] ml-2">
          {user ? user : 'loading'}
        </p>
      </div>
      <div className="flex w-full md:flex justify-between space-x-[15px] test">
        <Link
          to={`/patient`}
          className={`flex-1 ${
            activeLink(pathname) === 0 ? 'bg-dark-green' : 'bg-gray'
          } rounded-xl flex flex-col justify-center items-center contactButtons`}
        >
          <CalendarIcon width={18} color={activeLink(pathname) === 0 ? 'white' : '#4A4A4A'} />
          <p
            className={`${activeLink(pathname) === 0 ? 'text-white' : 'text-dark-gray'} mt-2 px-2`}
          >
            Termini
          </p>
        </Link>
        <Link
          to={`/patient/documents`}
          className={`flex-1 ${
            activeLink(pathname) === 1 ? 'bg-dark-green' : 'bg-gray'
          } rounded-xl flex flex-col justify-center items-center contactButtons`}
        >
          <DocumentDuplicateIcon
            width={18}
            color={activeLink(pathname) === 1 ? 'white' : '#4A4A4A'}
          />
          <p
            className={`${activeLink(pathname) === 1 ? 'text-white' : 'text-dark-gray'} mt-2 px-2`}
          >
            Dokumenta
          </p>
        </Link>
        <Link
          to="/patient/contact"
          className={`flex-1 ${
            activeLink(pathname) === 2 ? 'bg-dark-green' : 'bg-gray'
          } rounded-xl flex flex-col justify-center items-center contactButtons `}
        >
          <PhoneIcon width={18} color={activeLink(pathname) === 2 ? 'white' : '#4A4A4A'} />
          <p
            className={`${activeLink(pathname) === 2 ? 'text-white' : 'text-dark-gray'} mt-2 px-2`}
          >
            Kontakt
          </p>
        </Link>
      </div>
    </div>
  );
};

export default PatientLinks;
