import folderImg from '@/assets/folderImg.png';
import fileImg from '@/assets/fileImg.png';
import { formatDate } from '@/utils/format';
import { useEffect, useState } from 'react';
import { useRenameFolder } from '../../api/renameFolder';
import { Button } from '@/components/Elements';
import { axios } from '@/lib/axios';

export const Subfolders = ({ directory, activeIndex, isInEditMode, setRenderSubfiles, handleOnClick, path, setPath, setIsInEditMode, setParentId, setFilesCount }: any) => {

    const folders = directory.directories;
    const files = directory.files;
    const renameFolderMutation = useRenameFolder();
    const [newName, setNewName] = useState('');

    useEffect(() => {
        handleOnClick(0, false);
        setIsInEditMode(false);
        if (directory.files_count !== 0) {
            setFilesCount(directory.files_count)
        }
        localStorage.setItem('files_count', directory.files_count)
    }, [directory])

    const FileDownload = require('js-file-download');
    function downloadFile(id: any, name: any) {
        axios({
            url: `/files/${id}/download`,
            method: 'GET',
            responseType: 'blob',
        }).then((response: any) => {
            FileDownload(response, name);
        })
    }

    const folderList = folders.map((folder: any) => {
        return (
            <div
                className={activeIndex === folder.id ? 'activeFolder bg-light-green flex mt-3 h-12 leading-[48px] rounded-xl select-none cursor-pointer text-sm text-[#4A4A4A]' : 'text-sm text-[#4A4A4A] flex mt-3 bg-gray h-12 leading-[48px] rounded-xl select-none cursor-pointer'}
                key={folder.id}
                onClick={() => { handleOnClick(folder.id, true), setParentId(folder.parent_id) }}
                onDoubleClick={() => (setRenderSubfiles({ show: true, id: folder.id }), setPath([...path, folder.name]), handleOnClick(0, true))}
            >
                <div className="ml-5" id={folder.id}>
                    <img className="pb-1 pr-2 inline-block" src={folderImg}></img>
                    {isInEditMode && activeIndex === folder.id ?
                        <>
                            <input type="text" className="h-7 p-1" onChange={(e) => setNewName(e.target.value)} defaultValue={folder.name} />
                            <Button
                                variant='danger'
                                size="sm"
                                className="inline-flex ml-2"
                                onClick={() => setIsInEditMode(false)}
                            >
                                X
                            </Button>
                            <Button
                                isLoading={renameFolderMutation.isLoading}
                                size="sm"
                                className="my-bg-green ml-2"
                                onClick={() => renameFolderMutation.mutate({ activeIndex: activeIndex, name: newName })}
                            >
                                OK
                            </Button>
                        </>
                        :
                        <span>{folder.name}</span>
                    }
                </div>
                <div className="ml-auto mr-5">
                    {formatDate(folder.updated_at)}
                </div>
            </div>
        )
    })

    const fileList = files.map((file: any) => {
        return (
            <div
                className={activeIndex === file.id ? 'activeFolder bg-light-green flex mt-3 h-12 leading-[48px] rounded-xl select-none cursor-pointer text-sm text-[#4A4A4A]' : 'text-sm text-[#4A4A4A] flex mt-3 bg-gray h-12 leading-[48px] rounded-xl select-none cursor-pointer'}
                key={file.id}
                onClick={() => { handleOnClick(file.id, false) }}
                onDoubleClick={() => downloadFile(file.id, file.name)}
            >
                <div className="ml-5">
                    <img className="pb-1 pr-2 inline-block" src={fileImg}></img>
                    <span>{file.name}</span>
                </div>
                <div className="ml-auto mr-5">
                    <span className={`mr-5 type-style type-${file.type}`}>{file.type.charAt(0).toUpperCase() + file.type.slice(1)}</span>
                    <span>{formatDate(file.updated_at)}</span>
                </div>
            </div>
        )
    })

    return (
        <>
            {folders && (folderList)}
            {files && (fileList)}
        </>
    )
}