import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MeasurementsTable } from './MeasurementsTable';
import { Pagination } from '@/components/Elements/Pagination/Pagination';
import { AddMeasurements } from './AddMeasurements';
import { useMeasurements } from '../../api/getMeasurements';
import { Spinner } from '@/components/Elements';

export const ViewMeasurements = () => {
    let { state }: any = useLocation();
    const id = state.id;
    const [searchParam, setSearchParam] = useState({ page: '' });
    const filter: any = `?filter[user_id]=${id}&page=${searchParam['page']}`;
    const measurementsQuery: any = useMeasurements({ filter });

    if (measurementsQuery.isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    if (!measurementsQuery) return null;
    const currentPage = measurementsQuery?.data?.meta.current_page;
    const lastPage = measurementsQuery?.data?.meta.last_page;

    return (
        <div className="flex flex-col">
            <div className="flex z-10 col bg-[#39433B] w-full h-[72px] items-center rounded-t-xl border-[#979393] border-b-[1px] mb-[-10px]">
                <p className="ml-4 text-[#FAFAFA] tracking-[0.25px] text-[18px] leading-[21px] ">
                    Merenje - {state.user.name} {state.age && (`(` + state.age) + `)`}{state.height && (`,` + state.height + `cm`)}
                </p>
                <AddMeasurements id={state.id} state={state} />
            </div>

            <MeasurementsTable measurementsQuery={measurementsQuery} />
            <Pagination
                lastPage={lastPage}
                searchParam={searchParam}
                setSearchParam={setSearchParam}
                currentPage={currentPage}
            />

        </div>
    );
};
