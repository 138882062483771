import * as React from 'react';

import { Comment } from '@/features/comments';
import { User } from '@/features/users';

import { useAuth } from './auth';

export enum ROLES {
  ADMIN = 'admin',
  USER = 'user',
}

type RoleTypes = keyof typeof ROLES;

export const POLICIES = {
  'comment:delete': (user: User, comment: Comment) => {
    if (user.roles[0].name === 'admin') {
      return true;
    }

    if (user.roles[0].name === 'user' && comment.authorId === user.id) {
      return true;
    }

    return false;
  },
};

export const useAuthorization = () => {
  const { user } = useAuth();

  if (!user) {
    throw Error('User does not exist!');
  }

  const checkAccess = React.useCallback(
    ({ allowedRoles }: { allowedRoles: RoleTypes | any }) => {
      if (allowedRoles && allowedRoles.length > 0) {
        return allowedRoles?.includes(user.roles[0].name);
      }

      return true;
    },
    [user.roles]
  );

  return { checkAccess, role: user.roles[0].name };
};

type AuthorizationProps = {
  forbiddenFallback?: React.ReactNode;
  children: React.ReactNode;
} & (
    | {
      allowedRoles: any;
      policyCheck?: never;
    }
    | {
      allowedRoles?: never;
      policyCheck: boolean;
    }
  );

export const Authorization = ({
  policyCheck,
  allowedRoles,
  forbiddenFallback = null,
  children,
}: AuthorizationProps) => {
  const { checkAccess } = useAuthorization();

  let canAccess = false;

  if (allowedRoles) {
    canAccess = checkAccess({ allowedRoles });
  }

  if (typeof policyCheck !== 'undefined') {
    canAccess = policyCheck;
  }

  return <>{canAccess ? children : forbiddenFallback}</>;
};
