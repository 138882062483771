import { Table } from '@/components/Elements';
import { formatDate } from '@/utils/format';
import { DeleteMeasurements } from './DeleteMeasurements';

import { Measurements } from './types';

export const MeasurementsTable = ({ measurementsQuery }: any) => {
    const data = measurementsQuery.data.data;

    return (
        <Table<Measurements>
            data={data}
            columns={[
                {
                    title: 'Datum',
                    field: 'date_of_entry',
                    Cell({ entry: { date_of_entry } }) {
                        return <span>{formatDate(date_of_entry)}</span>;
                    },
                },
                {
                    title: 'Težina',
                    field: 'body_weight',
                    Cell({ entry: { body_weight } }) {
                        return <span>{body_weight}</span>;
                    },
                },
                {
                    title: 'Masti',
                    field: 'fat',
                    Cell({ entry: { fat } }) {
                        return (
                            <span>{fat}</span>
                        );
                    },
                },
                {
                    title: 'Visc. Masti',
                    field: 'visceral_fat',
                    Cell({ entry: { visceral_fat } }) {
                        return <div>{visceral_fat}</div>;
                    },
                },
                {
                    title: 'Mišići',
                    field: 'muscles',
                    Cell({ entry: { muscles } }) {
                        return <div>{muscles}</div>;
                    },
                },
                {
                    title: 'BMI',
                    field: 'bmi',
                    Cell({ entry: { bmi } }) {
                        return (
                            <span>{bmi}</span>
                        );
                    },
                },
                {
                    title: 'Voda',
                    field: 'water',
                    Cell({ entry: { water } }) {
                        return <span>{water}</span>;
                    },
                },
                {
                    title: 'Gustina',
                    field: 'density',
                    Cell({ entry: { density }, entry: { id } }) {
                        return (
                            <div className="flex">
                                <div className="">{density}</div>
                                <DeleteMeasurements measurementsIndex={id} />
                            </div>
                        );
                    },
                },
            ]}
        />
    );
};
