import { useEffect, useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import addPatient from '@/assets/icons/addPatient.png';
import { useDebounce } from '@/hooks/useDebounce';

import { Button } from '../Button';

import { AllFilters } from './AllFilters';
import { DatePicker } from './DatePicker';
import { ExportTable } from './ExportTable';
import { FilterDropdown } from './FilterDropdown';

export function SearchBar({ setParam, isAdmin, param, searchParam, setSearchParam, url }: any) {
  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  };

  const page = usePathname();
  const isPatientsPage = page === '/app/patients';
  const [showDatePicker, setShowDatePicker] = useState(false);
  const debouncedSearchParam = useDebounce(searchParam, 500);

  function encodeQueryData(data: any) {
    const ret = [];
    for (const d in data)
      if (d === 'page') ret.push(d + '=' + data[d]);
      else ret.push(`filter` + `[` + d + `]` + '=' + data[d]);
    return ret.join('&');
  }

  const querystring = '?' + encodeQueryData(searchParam);
  const searchData = localStorage.getItem(isPatientsPage ? 'searchParam' : 'searchParamPayment');
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (searchData) {
        setSearchParam(JSON.parse(searchData));
      }
    } else {
      setParam(querystring);
      localStorage.setItem(
        isPatientsPage ? 'searchParam' : 'searchParamPayment',
        JSON.stringify(searchParam)
      );
    }
  }, [debouncedSearchParam]);

  useEffect(() => {
    const unloadCallback = () => {
      localStorage.removeItem('searchParam');
      localStorage.removeItem('searchParamPayment');
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  const keyName: any = isPatientsPage ? 'user.name' : 'medicalRecord.user.name';
  const keyNameId: any = isPatientsPage ? 'record_number' : 'medicalRecord.record_number';

  return (
    <div className="searchBar sticky searchBarIndex">
      <div className="flex">
        <div className="relative mr-6 ">
          <input
            type="search"
            value={searchParam[keyNameId]}
            onChange={(e) =>
              setSearchParam({ ...searchParam, [`${keyNameId}`]: e.target.value, page: 1 })
            }
            className="  bg-gray shadow rounded border-0 p-2.5 inputWidth "
            placeholder="Pretraga po broju kartona"
          />
        </div>
        <div className="relative mr-6">
          <input
            type="search"
            value={searchParam[keyName]}
            onChange={(e) => setSearchParam({ ...searchParam, [`${keyName}`]: e.target.value })}
            className="bg-gray shadow rounded border-0 p-2.5 inputWidth"
            placeholder="Pretraga po imenu"
          />
        </div>
        <FilterDropdown
          searchParam={searchParam}
          setSearchParam={setSearchParam}
          isAdmin={isAdmin}
          isPatientsPage={isPatientsPage}
        />

        {isAdmin && !isPatientsPage && (
          <>
            <DatePicker
              searchParam={searchParam}
              setSearchParam={setSearchParam}
              showDatePicker={showDatePicker}
              setShowDatePicker={setShowDatePicker}
            />
          </>
        )}
        {isAdmin && (
          <ExportTable param={param} url={url} />
        )}
        {isAdmin && isPatientsPage && (
          <Link to="../new-patient" className="flex">
            <Button className=" flex flex-row justify-center ml-6 items-center mr-2 my-bg-green w-[215px] h-[44px] text-base pl-1 pr-1 text-white">
              <div className="flex items-center justify-items-center justify-around ">
                <img src={addPatient} alt="add icon" className="mr-3 " />
                Dodaj novog pacijenta
              </div>
            </Button>
          </Link>
        )}
      </div>

      <div className="mt-3">
        <AllFilters
          searchParam={searchParam}
          setSearchParam={setSearchParam}
          isAdmin={isAdmin}
          isPatientsPage={isPatientsPage}
        />
      </div>
    </div>
  );
}
