import * as React from 'react';

import logo from '@/assets/logo192.png';
import { Link } from '@/components/Elements';
import { Head } from '@/components/Head';

type LayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const Layout = ({ children, title }: LayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className="min-h-screen bg-white flex flex-col justify-center">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="flex justify-center">
            <Link className="flex items-center text-white" to="/">
              <img className="w-auto px-4" src={logo} alt="Workflow" />
            </Link>
          </div>

          <h2 className="mt-12 text-center text-3xl font-semibold text-gray-900">{title}</h2>
          <div className="bg-white py-8 px-4 sm:px-10">{children}</div>
        </div>
      </div>
    </>
  );
};
