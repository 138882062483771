import { Table } from '@/components/Elements';
import { formatDate } from '@/utils/format';

import { Payment } from '../types';

export const PaymentsList = ({ peymentsQuery }: any) => {
  const data = peymentsQuery.data.data;

  return (
    <Table<Payment>
      data={data}
      total={
        'Za trenutno podešene filtere ukupno: RSD: ' +
        peymentsQuery.data.meta.total_amount_rsd +
        ' EUR: ' +
        peymentsQuery.data.meta.total_amount_eur
      }
      columns={[
        {
          title: 'ID',
          field: 'medicalRecord',
          Cell({ entry: { medicalRecord } }) {
            return <span>{medicalRecord.record_number}</span>;
          },
        },
        {
          title: 'Ime i prezime',
          field: 'name',
          Cell({ entry: { medicalRecord } }) {
            return <span>{medicalRecord.user.name}</span>;
          },
        },
        {
          title: 'Faza',
          field: 'status',
          Cell({ entry: { medicalRecord } }) {
            return (
              <span className={`status status-${medicalRecord.status}`}>
                {medicalRecord.status.charAt(0).toUpperCase() + medicalRecord.status.slice(1)}
              </span>
            );
          },
        },
        {
          title: 'Tip dijete',
          field: 'diets',
          Cell({ entry: { medicalRecord } }) {
            const dietsName: Array<string> = [];
            medicalRecord.diets.map((diet: any) => {
              dietsName.push(diet.name);
            });
            return <div className="whitespace-normal">{dietsName.join(',')}</div>;
          },
        },
        {
          title: 'Tip oboljenja',
          field: 'diseases',
          Cell({ entry: { medicalRecord } }) {
            const diseasesName: Array<string> = [];
            medicalRecord.diseases.map((disease: any) => {
              diseasesName.push(disease.name);
            });
            return <div className="whitespace-normal">{diseasesName.join(',')}</div>;
          },
        },
        {
          title: 'Nutricionista',
          field: 'nutritionist',
          Cell({ entry: { medicalRecord } }) {
            return (
              <span id={medicalRecord.nutritionist.id}>{medicalRecord.nutritionist.name}</span>
            );
          },
        },
        {
          title: 'Datum',
          field: 'created_at',
          Cell({ entry: { created_at } }) {
            const date: any = new Date(created_at);
            return <span> {formatDate(date)}</span>;
          },
        },
        {
          title: 'Valuta i suma',
          field: 'amount_eur',
          Cell({ entry: { amount_eur }, entry: { amount_rsd } }) {
            let eur, eurText, rsd, rsdText;
            if (Math.floor(amount_eur)) {
              eur = Math.floor(amount_eur);
              eurText = 'EUR ';
            }

            if (Math.floor(amount_rsd)) {
              rsd = Math.floor(amount_rsd);
              rsdText = 'RSD ';
            }

            return (
              <>
                <span className="text-red-light">{rsdText}</span> <span>{rsd} </span>
                <span className="text-blue-light">{eurText}</span> <span>{eur}</span>
              </>
            );
          },
        },
      ]}
    />
  );
};
