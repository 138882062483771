import { useState } from 'react';
import { ConfirmationDialog } from '@/components/Elements';
import save from '@/assets/icons/save.png';
import { useAddMeasurements } from '../../api/useAddMeasurements';
import { PlusIcon } from '@heroicons/react/outline';

export const AddMeasurements = ({ id, state }: any) => {
    const today = new Date().toISOString().split("T")[0];
    const [measurements, setMeasurements] = useState({
        user_id: id,
        date_of_entry: today,
        body_weight: '',
        fat: '',
        visceral_fat: '',
        muscles: '',
        bmi: '',
        water: '',
        density: ''
    });
    const [disabled, setDisabled] = useState(false);

    const addMeasurementsMutation: any = useAddMeasurements();

    const clearState = () => {
        setMeasurements({
            user_id: id,
            date_of_entry: today,
            body_weight: '',
            fat: '',
            visceral_fat: '',
            muscles: '',
            bmi: '',
            water: '',
            density: ''
        })
    }

    return (
        <ConfirmationDialog
            isDone={addMeasurementsMutation.isSuccess}
            icon=""
            title="Unesite podatke novog merenja"
            cancelButtonText="Odustani"
            body={
                <div className="w-[450px] mt-2">
                    <p className="block text-base font-medium text-slate-700 mb-2">{state.user.name} {state.age && (`(` + state.age) + `)`}{state.height && (`,` + state.height + `cm`)}</p>
                    <label className="block">
                        <span className="block text-sm font-medium text-slate-700">
                            Kilaža
                        </span>
                        <input
                            type="text"
                            name="kilaza"
                            className="mt-1 mb-3 px-3 py-2 bg-[#F3F3F3] border border-gray shadow-sm border-slate-400 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                            placeholder="Unesite kilažu"
                            onChange={(e) => setMeasurements({ ...measurements, body_weight: e.target.value })}
                        />
                    </label>
                    <label className="block">
                        <span className="block text-sm font-medium text-slate-700">
                            Masti
                        </span>
                        <input
                            type="text"
                            name="masti"
                            className="mt-1 mb-3 px-3 py-2 bg-[#F3F3F3] border border-gray shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                            placeholder="Unesite masti"
                            onChange={(e) => setMeasurements({ ...measurements, fat: e.target.value })}
                        />
                    </label>
                    <label className="block">
                        <span className="block text-sm font-medium text-slate-700">
                            Viscelarne masti
                        </span>
                        <input
                            type="text"
                            name="viscelarne masti"
                            className="mt-1 mb-3 px-3 py-2 bg-[#F3F3F3] border border-gray shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                            placeholder="Unesite viscelarne masti"
                            onChange={(e) => setMeasurements({ ...measurements, visceral_fat: e.target.value })} />
                    </label>
                    <label className="block">
                        <span className="block text-sm font-medium text-slate-700">
                            Mišići
                        </span>
                        <input
                            type="text"
                            name="misici"
                            className="mt-1 mb-3 px-3 py-2 bg-[#F3F3F3] border border-gray shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                            placeholder="Unesite mišiće"
                            onChange={(e) => setMeasurements({ ...measurements, muscles: e.target.value })}
                        />
                    </label>
                    <label className="block">
                        <span className="block text-sm font-medium text-slate-700">
                            BMI
                        </span>
                        <input
                            type="text"
                            name="bmi"
                            className="mt-1 mb-3 px-3 py-2 bg-[#F3F3F3] border border-gray shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                            placeholder="Unesite BMI"
                            onChange={(e) => setMeasurements({ ...measurements, bmi: e.target.value })}
                        />
                    </label>
                    <label className="block">
                        <span className="block text-sm font-medium text-slate-700">
                            Voda
                        </span>
                        <input
                            type="text"
                            name="voda"
                            className="mt-1 mb-3 px-3 py-2 bg-[#F3F3F3] border border-gray shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Unesite vodu"
                            onChange={(e) => setMeasurements({ ...measurements, water: e.target.value })}
                        />
                    </label>
                    <label className="block">
                        <span className="block text-sm font-medium text-slate-700">
                            Gustina
                        </span>
                        <input
                            type="text"
                            name="gustina"
                            className="mt-1 mb-3 px-3 py-2 bg-[#F3F3F3] border border-gray shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Unesite gustinu"
                            onChange={(e) => setMeasurements({ ...measurements, density: e.target.value })}
                        />
                    </label>
                </div>
            }
            triggerButton={
                <button
                    className="rounded bg-gray p-2.5 px-4 text-left text-[#4A4A4A] ml-auto h-11 mr-4"
                >
                    <div className="flex items-center justify-items-center justify-around "
                        onClick={() => clearState()}
                    >
                        <PlusIcon width="20px" className="mr-3" />
                        Unesi novo merenje
                    </div>
                </button>
            }
            confirmButton={
                <>
                    <button
                        disabled={disabled}
                        className=" w-28 px-4 h-11 bg-[#35A6F9] text-[#FAFAFA] flex items-center cursor-pointer rounded"
                        onClick={() => {
                            addMeasurementsMutation.mutate({ measurements: measurements });
                            setDisabled(true);
                            setTimeout(
                                () => setDisabled(false),
                                1000
                            );
                        }}
                    >
                        <img src={save} className="mr-2 mb-[4px] h-4" alt="saveIcon" />
                        <span>Sačuvaj</span>
                    </button>
                </>
            }
        />
    );
};
