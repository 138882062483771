export const AllFilterDatePicker = ({ setSearchParam, searchParam }: any) => {
  const filterDateStart = searchParam['start_date'];
  const filterDateEnd = searchParam['end_date'];

  function removeFromFilter() {
    setSearchParam({ ...searchParam, start_date: '', end_date: '' });
  }

  function formatDateForFilter(date: any) {
    date = new Date(date);
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    return dd + '/' + mm + '/' + yyyy;
  }

  return (
    <>
      {filterDateStart && filterDateEnd && (
        <button
          className="bg-dark-green hover:opacity-20 text-white py-2 px-4 rounded mr-5 mb-5 text-sm"
          onClick={() => removeFromFilter()}
        >
          {formatDateForFilter(filterDateStart)} - {formatDateForFilter(filterDateEnd)}
          <span> X</span>
        </button>
      )}
    </>
  );
};
