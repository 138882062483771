import { DateAndTimePicker } from "./DateAndTimePicker";
import { useEffect } from "react";
import dayjs from 'dayjs';

export default function SwitchBtn({ setTimeForSend, setDateForSend, text, sendOption, setSendOption }: any) {

    const min = new Date().toISOString().split("T")[0];

    useEffect(() => {
        setDateForSend(dayjs(min).format('YYYY-MM-DD'));
        setTimeForSend('12:00');
    }, [sendOption])

    useEffect(() => {
        setSendOption(true)
    }, [])

    return (
        <>
            <div className="switch-btn-holder">
                <span className="font-medium text-base mr-12 mt-3">{text}</span>
                <label className="switch">
                    <input type="checkbox" defaultChecked
                        onChange={(e: any) =>
                            setSendOption(e.target.checked)
                        }
                    />
                    <span className="slider round"></span>
                </label>
            </div>
            {!sendOption && (
                <DateAndTimePicker setTimeForSend={setTimeForSend} setDateForSend={setDateForSend} sendOption={sendOption} />
            )}
        </>
    )
}