import { useState } from 'react';

import { Spinner } from '@/components/Elements';
import { Pagination } from '@/components/Elements/Pagination/Pagination';
import { SearchBar } from '@/components/Elements/SearchBar/SearchBar';
import { ContentLayout } from '@/components/Layout';
import { PaymentsList } from '@/features/payments/components/PaymentsList';

import { usePayments } from '../api/getPayments';

export const Payments = () => {
  const [param, setParam] = useState(
    '?page=filter[medicalRecord.record_number]=&filter[medicalRecord.user.name]=&filter[medicalRecord.status]=&filter[medicalRecord.nutritionist_id]=&filter[medicalRecord.diets.id]=&filter[medicalRecord.diseases.id]=&filter[currency]=&filter[start_date]=&filter[end_date]='
  );
  const peymentsQuery: any = usePayments({ filter: param });

  const [searchParam, setSearchParam] = useState({
    'medicalRecord.record_number': '',
    'medicalRecord.user.name': '',
    'medicalRecord.status': [],
    'medicalRecord.nutritionist_id': [],
    'medicalRecord.diets.id': [],
    'medicalRecord.diseases.id': [],
    currency: [],
    start_date: '',
    end_date: '',
  });

  if (peymentsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!peymentsQuery.data) return null;

  const currentPage = peymentsQuery?.data?.meta.current_page;
  const lastPage = peymentsQuery.data.meta.last_page;

  return (
    <ContentLayout title="Uplate">
      <SearchBar
        setParam={setParam}
        isAdmin={true}
        param={param}
        url='payments'
        searchParam={searchParam}
        setSearchParam={setSearchParam}
      />
      <PaymentsList peymentsQuery={peymentsQuery} />
      <Pagination
        lastPage={lastPage}
        searchParam={searchParam}
        setSearchParam={setSearchParam}
        currentPage={currentPage}
      />
    </ContentLayout>
  );
};
