import { useState } from 'react';
import Select from 'react-select';
import { Spinner } from '@/components/Elements';
import { useMoveFile } from '../../api/useMoveFile';
import { useGetAllDirectories } from '../../api/getAllDirectories';


export const MoveFile = ({ id, activeIndex }: any) => {

    const directoryQuery: any = useGetAllDirectories({ id });
    const [selectedStatus, setSelectedStatus]: any = useState(null);
    const moveFileMutation = useMoveFile();
    const customStyles = {
        control: (base: any) => ({
            ...base,
            height: 42,
            minHeight: 42
        })
    };

    const moveFile = (e: any) => {
        moveFileMutation.mutate({ id: activeIndex, directory_id: e.value });
    }

    if (directoryQuery.isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    if (!directoryQuery) return null;

    const statusOptions = directoryQuery.data.data.map(function (folder: any) {
        const folderName = folder.name === '/' ? '/  (pocetna strana)' : folder.name;
        return {
            value: folder.id, label: folderName
        };
    })

    return (
        <>
            <Select
                className="p-2.5 text-black mr-2"
                value={selectedStatus}
                onChange={(e) => { setSelectedStatus(e), moveFile(e) }}
                options={statusOptions}
                placeholder="Premesti u drugi folder"
                styles={customStyles}
            /></>

    )
} 