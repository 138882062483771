export const AllFilterCurrencyList = ({ setSearchParam, searchParam }: any) => {
  const filterCurrency = searchParam['currency'];

  const allFilterCurrencyList = filterCurrency.map((data: any) => {
    return (
      <button
        className="bg-dark-green hover:opacity-20 text-white py-2 px-4 rounded mr-5 mb-5 text-sm"
        key={data}
        onClick={() => {
          removeFromFilter(data);
        }}
      >
        {data} <span>X</span>
      </button>
    );
  });

  function removeFromFilter(data: any) {
    const newArr = [...searchParam['currency']];
    setSearchParam({ ...searchParam, ['currency']: newArr.filter((e) => e !== data) });
  }

  return <>{allFilterCurrencyList}</>;
};
