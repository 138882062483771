import { CalendarIcon, XIcon } from '@heroicons/react/outline';
import { addDays } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import { useQuery } from 'react-query';

import arrowDown from '@/assets/icons/arrow-down.png';
import { Spinner } from '@/components/Elements';
import { axios } from '@/lib/axios';

import {
  parseDate,
  parseDateIos,
  parseTime,
  parseToServerTime,
  calcWeekends,
} from '../api/useParseDate';
import { AppointmentProps } from '../types/index';

import { TerminCard, DateCard } from './TerminCard';

type AppointmentsProps = {
  openModal: (action: 'delete' | 'change', appointment: AppointmentProps) => void;
  setChangeAppointments: (a: boolean) => void;
  changeAppointments: boolean;
};

const weekends = calcWeekends(new Date(Date.now()), addDays(new Date(Date.now()), 14));
const fetchNextExam = (uuid: string | null) => {
  return axios.get(`/calendar-events/patient?uuid=${uuid}`);
};
const fetchAllExamsStatus = (uuid: string | null) => {
  return axios.get(`/occupied-calendar-events?uuid=${uuid}`, {});
};

const scrollToMyTermin = () => {
  const elmnt = document.getElementById('scrollTop');
  elmnt?.scrollIntoView();
};

const Appointments = ({
  openModal,
  setChangeAppointments,
  changeAppointments,
}: AppointmentsProps) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateSelected, setDateSelected] = useState<string>('');
  const [timeSelected, setTimeSelected] = useState('');
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const { isLoading, data, isError, error } = useQuery('next-exam', () =>
    fetchNextExam(localStorage.getItem('uuid'))
  );
  const { data: examsStatus }: any = useQuery('exam-status', () =>
    fetchAllExamsStatus(localStorage.getItem('uuid'))
  );
  useEffect(() => {
    if (data?.data[0]?.start) {
      const scheduledAppointment = new Date(parseDate(data?.data[0].start));
      setAppointmentArrayByDay(days[scheduledAppointment.getDay()]);
    }
  }, [data]);

  const [appointmentsArray, setAppointmentArray] = useState([
    '06:00',
    '06:10',
    '06:20',
    '06:30',
    '06:40',
    '06:50',
    '07:00',
    '07:10',
    '07:20',
    '07:30',
    '07:40',
    '07:50',
    '08:00',
    '08:10',
    '08:20',
    '08:30',
    '08:40',
    '08:50',
    '09:00',
    '09:10',
    '09:20',
  ]);

  const handleSelect = (data: Date): void => {
    const element = document.getElementById('termin-container');
    element!.scrollTo(0, 0);
    setDateSelected(dayjs(data).format('MMM DD YYYY'));
    setShowCalendar(false);
    const d = new Date(dayjs(data).format('MMM DD YYYY'));
    const dayName = days[d.getDay()];
    setAppointmentArrayByDay(dayName);
  };

  const setAppointmentArrayByDay = (dayName: any) => {
    if (dayName === 'Tuesday' || dayName === 'Friday') {
      setAppointmentArray([
        '06:00',
        '06:10',
        '06:20',
        '06:30',
        '06:40',
        '06:50',
        '07:00',
        '07:10',
        '07:20',
        '07:30',
        '07:40',
        '07:50',
        '08:00',
        '08:10',
        '08:20',
        '08:30',
        '08:40',
        '08:50',
        '09:00',
        '09:10',
        '09:20',
      ]);
    } else {
      setAppointmentArray([
        '07:00',
        '07:10',
        '07:20',
        '07:30',
        '07:40',
        '07:50',
        '08:00',
        '08:10',
        '08:20',
        '08:30',
        '08:40',
        '08:50',
        '09:00',
        '09:10',
        '09:20',
      ]);
    }
  };

  useEffect(() => {
    changeAppointments && scrollToMyTermin();
  }, [changeAppointments]);

  useEffect(() => {
    return () => setChangeAppointments(false);
  }, []);

  return (
    <div className="bg-white rounded-xl overflow-hidden">
      <div className="px-3 py-5 bg-Udark-gray flex justify-between items-center h-[72px]">
        <div className="flex items-center ">
          <CalendarIcon width={17} color="white" />
          <p className=" ml-2 text-[#FAFAFA] tracking-[0.25px] text-[18px] leading-[21px]">
            {changeAppointments ? 'Izaberi sledeći termin' : 'Vaš sledeći pregled'}
          </p>
        </div>
        {changeAppointments && (
          <div
            onClick={() => {
              setChangeAppointments(false);
              setShowCalendar(false);
            }}
            className="cursor-pointer"
          >
            <XIcon width={24} color="white" />
          </div>
        )}
      </div>
      {!changeAppointments && (
        <div className="p-3">
          {isLoading ? (
            <Spinner />
          ) : data?.data.length === 0 ? (
            <p className="text-center py-4 text-dark-gray">Nemate zakazanih pregleda</p>
          ) : (
            <DateCard openModal={openModal} timeDate={data?.data[0]} />
          )}
          {!examsStatus ? (
            <Spinner />
          ) : (
            data?.data.length > 0 && (
              <div className="border-t border-[#F1F1F1]">
                <p
                  className="text-center text-[#2E7CF6] mt-6 cursor-pointer"
                  onClick={() => {
                    setDateSelected(parseDateIos(data?.data[0].start));
                    setTimeSelected(parseTime(data?.data[0].start));
                    setChangeAppointments(true);
                  }}
                >
                  Promeni termin
                </p>
              </div>
            )
          )}
        </div>
      )}
      {changeAppointments && (
        <div className="p-3 text-center">
          <div className="pb-3 border-b border-[#F1F1F1]">
            <div
              className="border border-[#E6E6E6] p-3 flex justify-between items-center rounded cursor-pointer"
              onClick={() => setShowCalendar(!showCalendar)}
            >
              <span className="text-[#0C871B] font-medium">
                {dayjs(dateSelected).format('DD. MMM YYYY')}
              </span>
              <img src={arrowDown} alt="arrow down" width={'13px'} />
            </div>
            {showCalendar && (
              <Calendar
                onChange={handleSelect}
                showMonthAndYearPickers={false}
                date={new Date(dateSelected)}
                minDate={new Date(Date.now())}
                maxDate={addDays(new Date(Date.now()), 14)}
                disabledDates={weekends}
              />
            )}
          </div>
          <div className="overflow-y-scroll max-h-[300px]" id="termin-container">
            {appointmentsArray.map((el) => {
              const myTermin =
                el === timeSelected &&
                parseDate(data?.data[0].start) === dayjs(dateSelected).format('DD. MMM YYYY');
              const occupy =
                examsStatus[parseToServerTime(dayjs(dateSelected).format('DD. MMM YYYY'), el)] &&
                !myTermin;
              return (
                <div
                  key={el}
                  onClick={() => {
                    if (myTermin || occupy) return;
                    openModal('change', {
                      event_id: data?.data[0].event_id,
                      start: `${parseToServerTime(dayjs(dateSelected).format('DD. MMM YYYY'), el)}`,
                      end: '',
                    });
                  }}
                >
                  <TerminCard el={el} type={myTermin ? 'my' : occupy ? 'occupy' : 'default'} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Appointments;
