import ReactPaginate from 'react-paginate';

export const Pagination = ({ lastPage, searchParam, setSearchParam, currentPage }: any) => {
  const handlePageClick = (data: any) => {
    setSearchParam({ ...searchParam, page: data.selected + 1 });
  };

  return (
    <ReactPaginate
      previousLabel={'< prethodna'}
      nextLabel={'sledeća >'}
      breakLabel={'...'}
      pageCount={lastPage}
      marginPagesDisplayed={2}
      pageRangeDisplayed={3}
      onPageChange={handlePageClick}
      containerClassName={'flex items-center justify-center -space-x-px mt-12 '}
      pageLinkClassName={
        'bg-white border-green text-gray-500 hover:bg-gray-50 inline-flex relative items-center px-4 py-2 border text-sm font-medium'
      }
      previousLinkClassName={
        'bg-white border-green text-gray-500 hover:bg-gray-50 inline-flex relative items-center px-4 py-2 border text-sm font-medium'
      }
      nextLinkClassName={
        'bg-white border-green text-gray-500 hover:bg-gray-50 inline-flex relative items-center px-4 py-2 border text-sm font-medium'
      }
      breakLinkClassName={
        'bg-white border-green text-gray-500 hover:bg-gray-50 inline-flex relative items-center px-4 py-2 border text-sm font-medium'
      }
      activeClassName={'border-green text-green'}
      disabledClassName={'opacity-50'}
      forcePage={currentPage - 1}
    />
  );
};
