import { Button } from '@/components/Elements';
import { useRenameFolder } from '../../api/renameFolder';
import penIcon from '@/assets/icons/pen.png';

export const RenameFolder = ({ isInEditMode, setIsInEditMode }: any) => {
    const renameFolderMutation = useRenameFolder();

    return (
        <Button
            isLoading={renameFolderMutation.isLoading}
            type="button"
            className="bg-gray p-2.5 px-4 text-[#4A4A4A] mr-2 ml-4"
            onClick={() => { setIsInEditMode(!isInEditMode) }}
        >
            <img className="inline-block pb-1 pr-2" src={penIcon}></img>
            Preimenuj
        </Button>
    );
};
