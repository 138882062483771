import { TrashIcon } from '@heroicons/react/outline';
import React from 'react';

import checkIcon from '@/assets/icons/check-white.png';
import circleIcon from '@/assets/icons/circle-dark.png';

import { parseDate, parseTime } from '../api/useParseDate';
import { AppointmentProps } from '../types/index';

type TerminCard = {
  el: string;
  type?: 'default' | 'occupy' | 'my';
};

type DateCard = {
  openModal?: (a: 'delete', b: AppointmentProps) => void;
  timeDate: AppointmentProps | null | undefined;
};

const fieldStylesMapping = {
  default: `bg-gray flex-1 flex items-center px-5 py-4 rounded-xl border border-[#D8D8D8] h-[48px] cursor-pointer`,
  my: `bg-dark-green flex-1 flex items-center px-5 py-4 rounded-xl h-[50px]`,
  occupy: `bg-[#F9F9F9] flex-1 flex items-center px-5 py-4 rounded-xl h-[50px]`,
};

const textColor = {
  default: `text-dark-gray`,
  my: 'text-white',
  occupy: 'text-[#B0B0B0]',
};

const timeColor = {
  my: 'text-[#1c921f] text-[13px]',
  default: 'text-dark-gray text-[13px]',
  occupy: 'text-dark-gray text-[13px]',
};

const textContent = {
  my: 'Vaš termin',
  default: 'Slobodan termin',
  occupy: 'Zauzet termin',
};

export const TerminCard = ({ el, type = 'default' }: TerminCard) => {
  return (
    <div key={el} className="flex space-x-[12px] py-[6px]" id={type === 'my' ? 'scrollTop' : ''}>
      <span className={timeColor[type]}>{el}h</span>
      <div className={fieldStylesMapping[type]}>
        {type === 'my' && <img src={checkIcon} alt="check icon" width="17px" height="17px" />}
        {type === 'default' && (
          <img src={circleIcon} alt="circle icon" width="17px" height="17px" />
        )}
        <p className={`ml-3 font-medium ${textColor[type]}`}>{textContent[type]}</p>
      </div>
    </div>
  );
};

export const DateCard = ({ openModal, timeDate }: DateCard) => {
  return (
    <div className="flex space-x-[12px] mb-3  h-[72px]">
      <span className="text-[13px] text-[#1c921f]">{parseTime(timeDate!.start)}h</span>
      <div className="bg-dark-green flex-1 flex items-center px-5 py-4 rounded-xl justify-between">
        <div className="flex items-center">
          <img src={checkIcon} alt="check icon" width="17px" height="17px" />
          <p className="ml-3 text-white font-medium">{parseDate(timeDate!.start)}</p>
        </div>
        {openModal && (
          <div className="cursor-pointer" onClick={() => openModal('delete', timeDate!)}>
            <TrashIcon color="white" width={24} />
          </div>
        )}
      </div>
    </div>
  );
};
