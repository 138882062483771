import { TrashIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@/lib/auth';
import { ROLES } from '@/lib/authorization';

import { Button } from '../Button';
import { ConfirmationDialog } from '../ConfirmationDialog';

import { useDeletePatient } from './api/deletePatients';

export default function DeleteButton({ patientId }: any) {
  const [isDone, setIsDone] = useState(false);
  const deletePatientMutation = useDeletePatient();
  const navigate = useNavigate();
  const { user } = useAuth();
  if (user?.roles[0].name === ROLES.ADMIN)
    return (
      <>
        <button></button>
        <ConfirmationDialog
          isDone={isDone}
          icon="danger"
          title="Brisanje pacijenta"
          cancelButtonText="Otkaži"
          body={<span>Da li ste sigurni da želite da obrišete pacijenta?</span>}
          triggerButton={
            <div className=" w-fit px-4 h-11 bg-red-600 text-[#FAFAFA] flex items-center cursor-pointer rounded mr-2">
              <TrashIcon width="20px" className="mb-[4px] mr-2" />
              <span>Obriši pacijenta</span>
            </div>
          }
          confirmButton={
            <Button
              type="button"
              className="my-bg-green ml-3"
              onClick={() => {
                deletePatientMutation.mutate({ activeIndex: patientId }), setIsDone(!isDone);
                navigate('/');
              }}
            >
              Obriši pacijenta
            </Button>
          }
        />
      </>
    );
  else return <></>;
}
