import { useNutritions } from '../api/getSearchData';

export const Nutritionist = ({ setSearchParam, searchParam, isAdmin, isPatientsPage }: any) => {
  const keyName = isPatientsPage ? 'nutritionist_id' : 'medicalRecord.nutritionist_id';
  let nutritionsQuery: any;

  if (isAdmin) {
    nutritionsQuery = useNutritions();
  }

  if (!nutritionsQuery?.data) return null;

  function sendNutritionId(id: any, checked: any) {
    const newArr = [...searchParam[keyName]];
    newArr.push(id);
    if (checked) {
      setSearchParam({ ...searchParam, [`${keyName}`]: newArr, page: 1 });
    } else {
      setSearchParam({ ...searchParam, [`${keyName}`]: newArr.filter((e) => e !== id) });
    }
  }

  const usersList = nutritionsQuery.data.data.map((nutritionist: any) => {
    return (
      <li key={nutritionist.id}>
        <label>
          <input
            type="checkbox"
            id={nutritionist.id}
            className="mr-1"
            checked={Object(searchParam[`${keyName}`]).includes(`${nutritionist.id}`)}
            onChange={(e) => {
              sendNutritionId(e.target.id, e.target.checked);
            }}
          />
          {nutritionist.name}
        </label>
      </li>
    );
  });

  return <>{usersList}</>;
};
