import axios from 'axios';
import { useQuery } from 'react-query';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import { API_URL } from '@/config';
import storage from '@/utils/storage';

import { UserResponse } from '../types';

import { ChangePassword } from './ChangePassword';
import { Login } from './Login';
import { Register } from './Register';
import { SetPassword } from './SetPassword';

export const AuthRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (location.pathname !== '/auth/password-reset') {
    const loginUuid = async (location: string) => {
      const res = await axios.get(`${API_URL}/auth/login/patient${location}`);
      return res;
    };

    const onSuccess = (data: UserResponse) => {
      storage.setToken(data.access_token);
      localStorage.setItem('uuid', location.search.slice(6));
      navigate('/auth/login/patient');
      window.location.reload();
    };

    useQuery('uuid-patient', () => loginUuid(location.search), {
      enabled: location.search ? true : false,
      onSuccess,
      select: (data) => data.data,
    });
  }

  return (
    <Routes>
      <Route path="register" element={<Register />} />
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ChangePassword />} />
      <Route path="password-reset/*" element={<SetPassword />} />
    </Routes>
  );
};
