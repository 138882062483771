import { useState } from 'react';

import { Spinner } from '@/components/Elements';
import { Pagination } from '@/components/Elements/Pagination/Pagination';
import { SearchBar } from '@/components/Elements/SearchBar/SearchBar';
import { ContentLayout } from '@/components/Layout';
import { useAuth } from '@/lib/auth';
import { ROLES } from '@/lib/authorization';

import { usePatients } from '../api/getPatients';
import { PatientsAdmin } from '../components/PatientsAdmin';
import { PatientsUser } from '../components/PatientsUser';

export const Patients = () => {
  const [param, setParam] = useState(
    '?filter[record_number]=&filter[user.name]=&filter[status]=&filter[nutritionist_id]=&filter[diets.id]=&filter[diseases.id]='
  );
  const usersQuery: any = usePatients({ filter: param });
  const { user } = useAuth();
  const isAdmin = user?.roles[0].name === ROLES.ADMIN;

  const [searchParam, setSearchParam] = useState({
    record_number: '',
    'user.name': '',
    status: [],
    nutritionist_id: [],
    'diets.id': [],
    'diseases.id': [],
  });

  if (usersQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!usersQuery) return null;
  const currentPage = usersQuery?.data?.meta.current_page;
  const lastPage = usersQuery?.data?.meta.last_page;

  return (
    <ContentLayout title="Pacijenti">
      <SearchBar
        setParam={setParam}
        param={param}
        url='medical-records'
        isAdmin={isAdmin}
        searchParam={searchParam}
        setSearchParam={setSearchParam}
      />
      {isAdmin && <PatientsAdmin usersQuery={usersQuery} />}
      {!isAdmin && <PatientsUser usersQuery={usersQuery} user={user} />}
      <Pagination
        lastPage={lastPage}
        searchParam={searchParam}
        setSearchParam={setSearchParam}
        currentPage={currentPage}
      />
    </ContentLayout>
  );
};
