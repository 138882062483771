import { Link } from 'react-router-dom';

import { Table } from '@/components/Elements';

import { Patient } from '../types';
import { useAuth } from '@/lib/auth';

export const PatientsUser = ({ usersQuery, user }: any) => {
  let filterNutritionistId;
  const currentNutritionist: any = useAuth();

  usersQuery.data.data.map((data: any) => {
    filterNutritionistId = data.nutritionist.id;
  });

  const isSamePerson = user.id === filterNutritionistId;

  return (
    <Table<Patient>
      data={usersQuery.data.data}
      total={'Za trenutno podešene filtere ukupno: ' + usersQuery.data.meta.total + ' pacijenata'}
      columns={[
        {
          title: 'Id',
          field: 'record_number',
        },
        {
          title: 'Ime i prezime',
          field: 'name',
          Cell({
            entry: { user },
            entry: { record_number },
            entry: { status },
            entry: { nutritionist },
            entry: { diets },
            entry: { diseases },
            entry: { note },
            entry: { age },
            entry: { date_of_birth },
            entry: { payments },
            entry: { id },
            entry: { directory },
            entry: { files_count },
            entry: { height },

          }) {
            const patientData = {
              user: user,
              record_number: record_number,
              status: status,
              nutritionist: nutritionist,
              diets: diets,
              diseases: diseases,
              note: note,
              age: age,
              date_of_birth: date_of_birth,
              payments: payments,
              id: id,
              directory: directory,
              files_count: files_count,
              height: height,
            };
            if (isSamePerson) {
              let nutritionistData: any = patientData.nutritionist;
              let nutritionistId: any = nutritionistData.id;
              if (currentNutritionist.user.id === nutritionistId) {
                return (
                  <span>
                    <Link
                      state={patientData}
                      className="text-blue-link"
                      to={'../patients-card?name=' + user.name.replace(/\s/g, '')}
                      onContextMenu={() =>
                        localStorage.setItem(
                          `referrer-${user.name.replace(/\s/g, '')}`,
                          JSON.stringify(patientData)
                        )
                      }
                    >
                      {user.name}
                    </Link>
                  </span>
                )
              }
              else {
                return <span>{user.name}</span>;
              }


            } else {
              return <span>{user.name}</span>;
            }
          },
        },
        {
          title: 'Faza',
          field: 'status',
          Cell({ entry: { status } }) {
            if (isSamePerson) {
              return (
                <span className={`status status-${status}`}>
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </span>
              );
            } else {
              return null;
            }
          },
        },
        {
          title: 'Tip dijete',
          field: 'diets',
          Cell({ entry: { diets } }) {
            const dietsName: Array<string> = [];
            if (diets instanceof Array) {
              diets.map((diet: any) => {
                dietsName.push(diet.name);
              });
            }

            if (isSamePerson) {
              return dietsName.join(',');
            } else {
              return null;
            }
          },
        },
        {
          title: 'Tip oboljenja',
          field: 'diseases',
          Cell({ entry: { diseases } }) {
            const diseasesName: Array<string> = [];
            diseases.map((disease: any) => {
              diseasesName.push(disease.name);
            });

            if (isSamePerson) {
              return <span> {diseasesName.join(',')} </span>;
            } else {
              return null;
            }
          },
        },
        {
          title: 'Nutricionista',
          field: 'nutritionist',
          Cell({ entry: { nutritionist } }: any) {
            return <span id={nutritionist.id}>{nutritionist.name}</span>;
          },
        },
      ]}
    />
  );
};
