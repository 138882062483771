import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { Payments } from '../types';

export type CreatePaymentDTO = {
  medical_record_id: number;
  amount_rsd: string;
  amount_eur: string;
};

export const createPayment = (body: CreatePaymentDTO) => {
  return axios.post(`/payments`, body);
};

type UseCreatePaymentOptions = {
  config?: MutationConfig<typeof createPayment>;
};

export const useCreatePayment = ({ config }: UseCreatePaymentOptions) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('payments');

      const previousPayments = queryClient.getQueryData<Payments[]>('payments');

      queryClient.setQueryData('payments', previousPayments);

      return { previousPayments };
    },
    onError: (_, __, context: any) => {
      if (context?.previousPayments) {
        queryClient.setQueryData('payments', context.previousPayments);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('payments');
      addNotification({
        type: 'success',
        title: 'Uplata je dodata',
      });
    },
    ...config,
    mutationFn: createPayment,
  });
};
