export const Status = ({ setSearchParam, searchParam, isPatientsPage }: any) => {
  const status = [{ name: 'Aktivan' }, { name: 'Održavanje' }, { name: 'Mirovanje' }];

  const keyName = isPatientsPage ? 'status' : 'medicalRecord.status';

  function sendStatusName(name: any, checked: any) {
    const newArr = [...searchParam[keyName]];
    newArr.push(name);
    if (checked) {
      setSearchParam({ ...searchParam, [`${keyName}`]: newArr, page: 1 });
    } else {
      setSearchParam({ ...searchParam, [`${keyName}`]: newArr.filter((e) => e !== name) });
    }
  }

  const statusList = status.map((data) => {
    return (
      <li key={data.name}>
        <label>
          <input
            type="checkbox"
            name={data.name}
            className="mr-2"
            checked={Object(searchParam[`${keyName}`]).includes(`${data.name}`)}
            onChange={(e) => {
              sendStatusName(e.target.name, e.target.checked);
            }}
          />
          {data.name}
        </label>
      </li>
    );
  });

  return <>{statusList}</>;
};
