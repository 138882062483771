import axios from 'axios';
import React, { Dispatch, SetStateAction } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { API_URL } from '@/config';
import storage from '@/utils/storage';

import { AppointmentProps } from '../types/index';

const rescheduleCalendarEvent = (data: AppointmentProps | null | undefined): Promise<any> => {
  return axios.put(
    `${API_URL}/calendar-events/${data!.event_id}/reschedule?uuid=${localStorage.getItem('uuid')}`,
    {
      start: data!.start,
    }
  );
};

export const useRescheduleCalendarEvent = (
  setModal?: Dispatch<SetStateAction<boolean>>,
  setChangeAppointments?: Dispatch<SetStateAction<boolean>>
) => {
  const queryClient = useQueryClient();
  return useMutation(rescheduleCalendarEvent, {
    onSuccess: (data) => {
      setModal && setModal(false);
      setChangeAppointments && setChangeAppointments(false);
      queryClient.invalidateQueries('next-exam');
    },
  });
};
