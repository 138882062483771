import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';

import { Payment } from '../types';

export const getPayments = ({ filter }: { filter: string }): Promise<Payment[]> => {
  return axios.get(`/payments${filter}`);
};

type UsePaymentsOptions = {
  filter: string;
  config?: QueryConfig<typeof getPayments>;
};

export const usePayments = ({ filter, config }: UsePaymentsOptions) => {
  return useQuery({
    ...config,
    queryKey: ['payments', filter],
    queryFn: () => getPayments({ filter }),
  });
};
