import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { MedicalRecord } from '../types';

export const getAllDirectories = ({ id }: { id: string }): Promise<MedicalRecord[]> => {
    return axios.get(`/directories/medical-records/${id}`);
};

type UseMedicalRecordOptions = {
    id: string;
    config?: QueryConfig<typeof getAllDirectories>;
};

export const useGetAllDirectories = ({ id, config }: UseMedicalRecordOptions) => {
    return useQuery({
        ...config,
        queryKey: ['directories/medical-records/', id],
        queryFn: () => getAllDirectories({ id }),
    });
};

