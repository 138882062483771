import { Layout } from '../components/Layout';
import { SetPswForm } from '../components/SetPswForm';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import { useSearchParams, useNavigate } from "react-router-dom";

export const SetPassword = () => {
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  function resetPsw(e: any) {

    const body = {
      email: email,
      password: e.password,
      token: token,
      password_confirmation: e.confirm
    }

    axios({
      url: `/password/reset`,
      method: 'POST',
      data: body
    }).then((response: any) => {
      addNotification({
        type: 'success',
        title: response.message
      });

      setTimeout(() => {
        navigate('/auth/login');
      }, 2000);
    })

  }

  return (
    <Layout title="Postavite lozinku">
      <p className="mb-5">
        Postavite lozinku a zatim je unesite ponovo u polje ispod. Kliknite na “Zavrsi” kako bi ste
        sacuvali novu lozinku, bicete prebaceni na login ekran.
      </p>
      <SetPswForm onSuccess={(e) => resetPsw(e)} />
    </Layout>
  );
};
