import penIcon from '@/assets/icons/trashBin.png';
import { Button, ConfirmationDialog } from '@/components/Elements';

import { useDeleteFile } from '../../api/deleteFile';

type DeleteFileProps = {
  activeIndex: number;
};

export const DeleteFile = ({ activeIndex }: DeleteFileProps) => {
  const deleteFileMutation = useDeleteFile();

  return (
    <ConfirmationDialog
      icon="danger"
      title="Brisanje fajla"
      cancelButtonText="Otkaži"
      body="Da li ste sigurni da želite da izbrišete fajl?"
      triggerButton={
        <Button className="bg-gray p-2.5 px-4 text-red-light mr-2 ml-4">
          <img className="inline-block pb-1 pr-2" src={penIcon}></img>
          Obriši
        </Button>
      }
      confirmButton={
        <Button
          isLoading={deleteFileMutation.isLoading}
          type="button"
          className="my-bg-green ml-3"
          onClick={() => deleteFileMutation.mutate({ activeIndex: activeIndex })}
        >
          Obriši fajl
        </Button>
      }
    />
  );
};
