import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

export type MedicalRecord = {
    id: number;
    activeIndex: number;
};

export type DeletePatientDTO = {
    activeIndex: number;
};

export const deletePatient = ({ activeIndex }: DeletePatientDTO) => {
    return axios.delete(`/medical-records/${activeIndex}`);
};

type UseDeletePatientOptions = {
    config?: MutationConfig<typeof deletePatient>;
};

export const useDeletePatient = ({ config }: UseDeletePatientOptions = {}) => {
    const { addNotification } = useNotificationStore();

    return useMutation({
        onMutate: async (deletedPatient) => {
            await queryClient.cancelQueries('medical-records');

            const previousPatients = queryClient.getQueryData<MedicalRecord[]>('medical-records');

            queryClient.setQueryData(
                'medical-records',
                previousPatients?.filter((patient) => patient.id !== deletedPatient.activeIndex)
            );

            return { previousPatients };
        },
        onError: (_, __, context: any) => {
            if (context?.previousFolders) {
                queryClient.setQueryData('medical-records', context.previousFolders);
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries('medical-records');
            addNotification({
                type: 'success',
                title: 'Pacijent uspešno obrisan',
            });
        },
        ...config,
        mutationFn: deletePatient,
    });
};
