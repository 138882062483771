import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';

export const getAllMeasurements = ({ filter }: { filter: string }) => {
    return axios.get(`/measurements${filter}`);
};

type UseMeasurementsOptions = {
    filter: string;
    config?: QueryConfig<typeof getAllMeasurements>;
};

export const useMeasurements = ({ filter, config }: UseMeasurementsOptions) => {
    return useQuery({
        ...config,
        queryKey: ['/measurements', filter],
        queryFn: () => getAllMeasurements({ filter }),
    });
};

