import { useLocation } from 'react-router-dom';

import hashtagIcon from '@/assets/icons/#.png';
import dietIcon from '@/assets/icons/diet.png';
import diseaseIcon from '@/assets/icons/disease.png';
import emailIcon from '@/assets/icons/email.png';
import healthIcon from '@/assets/icons/health.png';
import heartIcon from '@/assets/icons/heart.png';
import phoneIcon from '@/assets/icons/phone.png';
import profileIcon from '@/assets/icons/profile.png';
import { useAuth } from '@/lib/auth';
import { ROLES } from '@/lib/authorization';
import { formatDate } from '@/utils/format';

import { ViewPatientInfoColumn } from './ViewPatientInfoColumn';

export const ViewPatientInfo = () => {
  const { user } = useAuth();
  let { state }: any = useLocation();
  const url_string = window.location.href;
  const url = new URL(url_string);
  const name = url.searchParams.get('name');

  if (state === null) {
    const retrievedObject = localStorage.getItem(`referrer-${name}`);
    state = retrievedObject ? JSON.parse(retrievedObject) : null;
  }
  let dietsList: any = '';
  let diseaseList: any = '';
  state?.diets.map((diet: any) => {
    dietsList += diet.name + ', ';
  });
  state?.diseases.map((disease: any) => {
    diseaseList += disease.name + ', ';
  });

  console.log(state);
  return (
    <div className="flex flex-col w-full h-fit  rounded-xl">
      <div className="flex px-3 bg-[#39433B] rounded-t-xl	text-[#FAFAFA] h-[72px] items-center">
        <p className="ml-4 text-[#FAFAFA] tracking-[0.25px] text-[18px] leading-[21px]">
          Pregled Informacija
        </p>
      </div>
      <div className="flex flex-col bg-[#FFFFFF] rounded-b-xl h-[600px] ">
        <div className="flex flex-row mt-1 ">
          <div className="flex flex-col max-w-[511px] min-w-[300px] w-full">
            <ViewPatientInfoColumn>
              <p className="mt-1 ml-2 text-[10px]">ID</p>
              <div className="flex mt-2 ml-2 items-center">
                <img src={hashtagIcon} alt="hastag icon" className="w-[19px] h-[16px]" />
                <p className="ml-2 text-[18px] leading-[16px] text-[#4A4A4A] tracking-[0.25px] font-medium">
                  {state?.record_number}
                </p>
              </div>
            </ViewPatientInfoColumn>
            <ViewPatientInfoColumn>
              <p className="mt-1 ml-2 text-[10px]">Ime i prezime (godine)</p>
              <div className="flex mt-2 ml-2 items-center">
                <img src={profileIcon} alt="profile icon" className="w-[19px] h-[16px]" />
                <p className="ml-2 text-[18px] leading-[16px] text-[#4A4A4A] tracking-[0.25px] font-medium">
                  {state?.user.name} ({state?.age})
                </p>
              </div>
            </ViewPatientInfoColumn>
            <ViewPatientInfoColumn>
              <p className="mt-1 ml-2 text-[10px]">Faza</p>
              <div className="flex mt-2 ml-2 items-center">
                <img src={heartIcon} alt="heart icon" className="w-[19px] h-[16px]" />
                <p className="ml-2 text-[18px] leading-[16px] text-[#4A4A4A] tracking-[0.25px] font-medium">
                  {state?.status}
                </p>
              </div>
            </ViewPatientInfoColumn>
            {user?.roles[0].name === ROLES.ADMIN && (
              <ViewPatientInfoColumn>
                <p className="mt-1 ml-2 text-[10px]">Telefon</p>
                <div className="flex mt-2 ml-2 items-center">
                  <img src={phoneIcon} alt="phone icon" className="w-[19px] h-[16px]" />
                  <p className="ml-2 text-[18px] leading-[16px] text-[#4A4A4A] tracking-[0.25px] font-medium">
                    {state?.user.phone}
                  </p>
                </div>
              </ViewPatientInfoColumn>
            )}
            {user?.roles[0].name === ROLES.ADMIN && (
              <ViewPatientInfoColumn>
                <p className="mt-1 ml-2 text-[10px]">Email</p>
                <div className="flex mt-2 ml-2 items-center">
                  <img src={emailIcon} alt="email icon" className="w-[19px] h-[16px]" />
                  <p className="ml-2 text-[18px] leading-[16px] text-[#4A4A4A] tracking-[0.25px] font-medium">
                    {state?.user.email}
                  </p>
                </div>
              </ViewPatientInfoColumn>
            )}
            <ViewPatientInfoColumn>
              <p className="mt-1 ml-2 text-[10px]">Zaduzeni nutricionista</p>
              <div className="flex mt-2 ml-2 items-center">
                <img src={healthIcon} alt="health icon" className="w-[19px] h-[16px]" />
                <p className="ml-2 text-[18px] leading-[16px] text-[#4A4A4A] tracking-[0.25px] font-medium">
                  {state?.nutritionist.name}
                </p>
              </div>
            </ViewPatientInfoColumn>
          </div>
          <div className="flex flex-col max-w-[511px] min-w-[300px] w-full  mRight">
            {state.height && (
              <ViewPatientInfoColumn>
                <p className="mt-1 ml-2 text-[10px] ">Visina</p>
                <p className="ml-2 mt-1 divWrap text-[18px] leading-[16px] text-[#4A4A4A] tracking-[0.25px] font-medium">
                  {state.height}cm
                </p>
              </ViewPatientInfoColumn>
            )}
            <ViewPatientInfoColumn>
              <p className="mt-1 ml-2 text-[10px] ">Datum prvog dolaska</p>
              <p className="ml-2 mt-1 divWrap text-[18px] leading-[16px] text-[#4A4A4A] tracking-[0.25px] font-medium">
                {formatDate(state.date_of_first_arrival)}
              </p>
            </ViewPatientInfoColumn>
            <ViewPatientInfoColumn>
              <p className="mt-1 ml-2 text-[10px] ">Dijeta</p>
              <div className="flex mt-1 ml-2 items-center divWrap  h-auto ">
                <img src={dietIcon} alt="diet icon" className="w-[19px] h-[16px]" />

                <p className="divWrap ml-2 text-[18px] leading-[16px] text-[#4A4A4A] tracking-[0.25px] font-medium inline">
                  {dietsList.slice(0, -2)}
                </p>
              </div>
            </ViewPatientInfoColumn>
            <ViewPatientInfoColumn>
              <p className="mt-1 ml-2 text-[10px]">Oboljenja</p>
              <div className="flex mt-1 ml-2 divWrap items-center ">
                <img src={diseaseIcon} alt="disease icon" className="w-[19px] h-[16px]" />
                <div className="">
                  <p className=" divWrap ml-2 text-[18px] leading-[16px] text-[#4A4A4A] tracking-[0.25px] font-medium w-full ">
                    {diseaseList.slice(0, -2)}
                  </p>
                </div>
              </div>
            </ViewPatientInfoColumn>
          </div>
        </div>
        <div className="flex flex-col mt-7 ml-[26px]">
          <p className="text-[10px]">Napomena</p>
          <div className="max-w-[915px] h-[50px] text-[#4A4A4A] text-[16px] mt-1 leading-3-[19px] tracking-[0.25px]">
            {state?.note}
          </div>
        </div>
      </div>
    </div>
  );
};
