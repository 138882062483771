import { addDays, isWeekend } from 'date-fns';

const pad = (d: string | number) => {
  return d < 10 ? '0' + d.toString() : d.toString();
};

export const parseDate = (date: string) => {
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let array = date?.split(/\W/);
  return array && `${array[0]}. ${months[+array[1] - 1]} ${array[2]}`;
};

export const parseDateIos = (date: string) => {
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let array = date?.split(/\W/);
  return array && ` ${months[+array[1] - 1]} ${array[0]} ${array[2]}`;
};

export const parseTime = (date: string) => {
  let array = date?.split(/\W/);
  return array && `${array[3]}:${array[4]}`;
};

export const parseToServerTime = (date: string, time: string) => {
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let array = date?.split(/\W/);
  return array && `${array[0]}-${pad(months.indexOf(array[2]) + 1)}-${array[3]} ${time}`;
};

export const calcWeekends = (startDate: Date, endDate: Date) => {
  // if (startDate > endDate) return false;
  const date = startDate;
  let dates = [];

  while (date < endDate) {
    if (isWeekend(date)) dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};
