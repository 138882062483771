import React from 'react';
type Props = {
  children?: JSX.Element | JSX.Element[];
};
export const ViewPaymentsColumn = ({ children }: Props) => {
  return (
    <div className=" flex h-[42px] max-w-[1050px] mt-3 bg-[#FAFAFA] rounded-xl items-center">
      {children}
    </div>
  );
};
