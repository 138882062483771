import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';

import { Patient } from '../types';

export const getPatients = ({ filter }: { filter: string }): Promise<Patient[]> => {
  return axios.get(`/medical-records${filter}`);
};

type UsePatientsOptions = {
  filter: string;
  config?: QueryConfig<typeof getPatients>;
};

export const usePatients = ({ filter, config }: UsePatientsOptions) => {
  return useQuery({
    ...config,
    queryKey: ['medical-records', filter],
    queryFn: () => getPatients({ filter }),
  });
};
