export const AllFilterStatusList = ({ setSearchParam, searchParam, isPatientsPage }: any) => {
  const keyName = isPatientsPage ? 'status' : 'medicalRecord.status';
  const filterStatus = searchParam[`${keyName}`];

  const allFilterStatusList = filterStatus.map((data: any) => {
    return (
      <button
        className="bg-dark-green hover:opacity-20 text-white py-2 px-4 rounded mr-5 mb-5 text-sm"
        key={data}
        onClick={() => {
          removeFromFilter(data);
        }}
      >
        {data} <span>X</span>
      </button>
    );
  });

  function removeFromFilter(data: any) {
    const newArr = [...searchParam[`${keyName}`]];
    setSearchParam({ ...searchParam, [`${keyName}`]: newArr.filter((e) => e !== data) });
  }

  return <>{allFilterStatusList}</>;
};
