import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { Directories } from '../types';

export type NewFoldersOptions = {
  medical_record_id: number;
  parent_id: number;
  name: string;
};

export const updateFolders = (data: NewFoldersOptions) => {
  return axios.post(`/directories`, data);
};

type UseUpdateFoldersOptions = {
  config?: MutationConfig<typeof updateFolders>;
};

export const useAddFolder = ({ config }: UseUpdateFoldersOptions = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('directories');

      const previousFolders = queryClient.getQueryData<Directories[]>('directories');

      return { previousFolders };
    },
    onSuccess: () => {
      queryClient.invalidateQueries('directories');
      addNotification({
        type: 'success',
        title: 'Folder uspešno kreiran',
      });
    },
    ...config,
    mutationFn: updateFolders,
  });
};
