// import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import addIcon from '@/assets/icons/add.png';
import { Spinner } from '@/components/Elements';
import { useAuth } from '@/lib/auth';
import { ROLES } from '@/lib/authorization';
import { useNotificationStore } from '@/stores/notifications';
import { formatDate } from '@/utils/format';

import { Button } from '../Button';

import { useCreatePayment } from './api/getPayments';
import { usePayments } from './api/useAllPayments';
import { DeletePayment } from './components/DeletePayment';
import { ViewPaymentsColumn } from './ViewPaymentsColumn';

export const ViewPayments = () => {
  const { addNotification }: any = useNotificationStore();
  let { state }: any = useLocation();

  const url_string = window.location.href;
  const url = new URL(url_string);
  const name = url.searchParams.get('name');

  if (state === null) {
    const retrievedObject = localStorage.getItem(`referrer-${name}`);
    state = retrievedObject ? JSON.parse(retrievedObject) : null;
  }
  const { user } = useAuth();
  const { register, handleSubmit, reset } = useForm();
  const createFolderMutation = useCreatePayment(state?.id);

  const record_number_id = state?.id;

  const allPaymentsQuery: any = usePayments({ record_number_id });
  if (allPaymentsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  const submit = (data: any) => {
    if (data.amount_rsd || data.amount_eur) {
      const body = {
        medical_record_id: state?.id,
        amount_rsd: data.amount_rsd,
        amount_eur: data.amount_eur,
        date_of_payment: data.date_of_payment,
      };

      createFolderMutation.mutateAsync(body);
    } else {
      addNotification({
        type: 'error',
        title: 'Niste uneli uplatu!',
      });
    }
    reset({
      amount_rsd: '',
      amount_eur: '',
    });
  };
  console.log('ID' + state.id);
  if (!allPaymentsQuery) return null;
  return (
    <div className="flex flex-col">
      <div className="flex flex col bg-[#39433B] w-full h-[72px] items-center rounded-t-xl border-[#979393] border-b-[1px]">
        <p className="ml-4 text-[#FAFAFA] tracking-[0.25px] text-[18px] leading-[21px] ">
          Uplate ({state.user.name})
        </p>
      </div>
      {user?.roles[0].name === ROLES.ADMIN && (
        <form onSubmit={handleSubmit(submit)}>
          <div className="flex h-[72px] w-full bg-[#39433B] items-center justify-between">
            <input type="date" className="ml-4 formInput" {...register('date_of_payment')} />
            <div className="flex">
              <input
                type="number"
                placeholder="Unesi iznos RSD"
                className="w-[234px] h-[44px] mr-8 rounded p-2.5 outline-none noInputArrows"
                max="100000"
                min="0"
                {...register('amount_rsd')}
              />
              <input
                type="number"
                placeholder="Unesi iznos EUR"
                className="w-[234px] h-[44px] mr-5 rounded outline-none p-2.5 noInputArrows"
                max="1000"
                min="0"
                {...register('amount_eur')}
              />
              <Button
                type="submit"
                className=" flex flex-row justify-center items-center hover:bg-[#f7f4f4]  mr-4 bg-[#F2F2F2] border-[#F2F2F2] w-[183px] h-[44px] text-base pl-1 pr-1 text-[#4A4A4A]"
              >
                <div className="flex items-center justify-items-center justify-around ">
                  <img src={addIcon} alt="add icon" className="mr-3" />
                  Unesi novu uplatu
                </div>
              </Button>
            </div>
          </div>
        </form>
      )}

      <div className="flex items-center h-[42px] w-full bg-[#E6FFE3] rounded-b-xl">
        <p className="text-[#05831A] text-base ml-4 leading-4 font-medium">Datum</p>
        <p className="text-[#05831A] text-base ml-[250px] leading-4  font-medium paymentValueHeader">
          Valuta i suma
        </p>
      </div>

      {allPaymentsQuery?.data?.data.map((payment: any, index: any) => {
        return payment ? (
          <ViewPaymentsColumn key={index}>
            <p className="text-[13px] leading-4 ml-4 text-[#4A4A4A] tracking-[0.25px]">
              {formatDate(payment.created_at)}
            </p>
            <p className="text-[13px] leading-4 ml-60 text-[#4A4A4A] tracking-[0.25px]">
              <span className="text-[#E05D5D]">RSD </span>
              {payment.amount_rsd ? payment.amount_rsd : ''}
              <span className="text-[#4DA6D9]"> EUR </span>
              {payment.amount_eur ? payment.amount_eur : ''}
            </p>
            <DeletePayment activeIndex={payment.id} user={user?.roles[0].name} />
          </ViewPaymentsColumn>
        ) : (
          ''
        );
      })}
    </div>
  );
};
