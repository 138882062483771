import { useState } from 'react';

import folderIcon from '@/assets/icons/folder.png';
import { Button, ConfirmationDialog } from '@/components/Elements';

import { useAddFolder } from '../../api/createFolder';

type CreatingFolderProps = {
  id: any;
  directory: any;
  parentId: any;
  start: any;
};

export const CreatingFolder = ({ id, directory, parentId, start }: CreatingFolderProps) => {
  const [newName, setNewName] = useState('');
  const updateFoldersMutation = useAddFolder(id);
  const parent = start ? directory.id : parentId;

  const hendleCreate = async (data: any) => {
    await updateFoldersMutation.mutateAsync(data);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      hendleCreate({ medical_record_id: id, name: newName, parent_id: parent });
    }
  }

  return (
    <ConfirmationDialog
      isDone={updateFoldersMutation.isSuccess}
      icon="info"
      title="Kreiranje novog foldera"
      cancelButtonText="Otkaži"
      body={
        <>
          <label>Unesite ime novog foldera</label>
          <input
            className="ml-2 h-7 p-1 bg-gray"
            type="text"
            onChange={(e) => setNewName(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
          />
        </>
      }
      triggerButton={
        <button className="rounded bg-gray p-2.5 px-4 text-left text-[#4A4A4A] ml-auto h-11 mr-4">
          <img className="inline-block pb-1 pr-2" src={folderIcon}></img>
          Kreiraj novi folder
        </button>
      }
      confirmButton={
        <Button
          isLoading={updateFoldersMutation.isLoading}
          type="button"
          className="my-bg-green"
          onClick={() => hendleCreate({ medical_record_id: id, name: newName, parent_id: parent })}
        >
          Kreiraj
        </Button>
      }
    />
  );
};
