import sms from '@/assets/icons/sms.png';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';
import { Button, ConfirmationDialog } from '@/components/Elements';
import { useState } from 'react';
import dayjs from 'dayjs';
import SwitchBtn from '../AddDocuments/SwitchBtn';

export const SendSms = ({ medicalRecordId, state }: any) => {

    const { addNotification } = useNotificationStore();
    const medicalId = medicalRecordId ? medicalRecordId : state.id;
    const [isDone, setIsDone] = useState(false);
    const min = new Date().toISOString().split("T")[0];
    const [dateForSend, setDateForSend] = useState(dayjs(min).format('YYYY-MM-DD'));
    const [timeForSend, setTimeForSend] = useState('12:00');
    const text = 'Pošalji odmah SMS obaveštenje';
    const [sendOption, setSendOption] = useState(true);


    function sendSms(id: any) {
        axios({
            url: `/medical-records/${id}/sms`,
            method: 'GET',
        }).then((response: any) => {
            addNotification({
                type: 'success',
                title: response.message,
            });
            setIsDone(true);
        })
            .catch((error) => {
                setIsDone(true);
            })
            .then(() => { setIsDone(false) })
    }

    function scheduleSendSms(id: any) {
        axios({
            url: `/medical-records/${id}/sms?schedule_time=${dateForSend} ${timeForSend}`,
            method: 'GET',
        }).then((response: any) => {
            addNotification({
                type: 'success',
                title: response.message,
            });
            setIsDone(true);
        })
            .catch((error) => {
                setIsDone(true);
            })
            .then(() => {
                setIsDone(false)
            })
        setDateForSend(dayjs(min).format('YYYY-MM-DD'));
        setTimeForSend('12:00');
    }

    return (
        <div>
            <ConfirmationDialog
                isDone={isDone}
                icon="info"
                title="Pošalji SMS"
                cancelButtonText="Odustani"
                body={
                    <div>
                        <SwitchBtn setTimeForSend={setTimeForSend} setDateForSend={setDateForSend} text={text} sendOption={sendOption} setSendOption={setSendOption} />
                    </div>
                }
                triggerButton={
                    <div className="shadow-lg rounded w-32 py-4 mr-5 bg-white cursor-pointer"                    >
                        <div className="flex items-center justify-center">
                            <img className="w-auto" src={sms} alt="users img" />
                        </div>
                        <div className="flex flex-col justify-center">
                            <p className="text-[#4A4A4A] text-center mt-4">Pošalji SMS</p>
                        </div>
                    </div>
                }
                confirmButton={
                    <Button
                        type="button"
                        className="my-bg-green"
                        onClick={() => {
                            {
                                !sendOption && (
                                    scheduleSendSms(medicalId)
                                )
                            }
                            {
                                sendOption && (
                                    sendSms(medicalId)
                                )
                            }
                        }
                        }
                    >
                        Pošalji
                    </Button>
                }
            />

        </div>

    )
}