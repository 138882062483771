import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';

const schema = z
  .object({
    password: z
      .string()
      .nonempty('Obavezno polje')
      .min(6, { message: 'Lozinka mora imati najmanje 6 karaktera' }),
    confirm: z
      .string()
      .nonempty('Obavezno polje')
      .min(6, { message: 'Lozinka mora imati najmanje 6 karaktera' }),
  })
  .refine((data) => data.password === data.confirm, {
    message: 'Lozinke se ne poklapaju',
    path: ['confirm'], // path of error
  });

type PswValue = {
  password: string;
  confirm: string;
};

type SetPswFormProps = {
  onSuccess: (e: any) => void;
};

export const SetPswForm = ({ onSuccess }: SetPswFormProps) => {
  return (
    <div>
      <Form<PswValue, typeof schema> onSubmit={(e) => onSuccess(e)} schema={schema}>
        {({ register, formState }) => (
          <>
            <InputField
              type="password"
              label="Nova lozinka"
              placeholder="Lozinka"
              className="bg-gray"
              error={formState.errors['password']}
              registration={register('password')}
            />
            <InputField
              type="password"
              label="Ponovo unesi novu lozinku"
              placeholder="Lozinka"
              className="bg-gray"
              error={formState.errors['confirm']}
              registration={register('confirm')}
            />
            <div>
              <Button type="submit" className="w-full my-bg-green font-normal">
                Zavrsi
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-center">
        <div className="text-sm">
          <Link to="../auth/login" className="font-medium text-blue-600 hover:text-blue-500">
            Nazad na login
          </Link>
        </div>
      </div>
    </div>
  );
};
