import { Button, ConfirmationDialog } from '@/components/Elements';
import { useDeleteMeasurements } from '../../api/deleteMeasurements';
import { TrashIcon } from '@heroicons/react/outline';

type DeleteMeasurementsProps = {
    measurementsIndex: number;
};

export const DeleteMeasurements = ({ measurementsIndex }: DeleteMeasurementsProps) => {
    const deletePaymentMutation = useDeleteMeasurements();

    return (
        <ConfirmationDialog
            isDone={deletePaymentMutation.isSuccess}
            icon="danger"
            title="Brisanje merenja"
            cancelButtonText="Otkaži"
            body="Da li ste sigurni da želite da izbrišete merenje?"
            triggerButton={
                <button className="flex ml-auto mr-2 text-[#4a4a4a]">
                    <TrashIcon width="20px" />
                </button>
            }
            confirmButton={
                <Button
                    isLoading={deletePaymentMutation.isLoading}
                    type="button"
                    className="bg-red-600 ml-3"
                    onClick={() => deletePaymentMutation.mutate({ measurementsIndex: measurementsIndex })}
                >
                    Obriši merenje
                </Button>
            }
        />
    )
};
