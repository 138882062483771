import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

export type AddMeasurementsDTO = {
    measurements: any;
};

export const addMeasurements = ({ measurements }: AddMeasurementsDTO) => {
    return axios.post(`/measurements`, measurements);
};

type UseAddMeasurementsOptions = {
    config?: MutationConfig<typeof addMeasurements>;
};

export const useAddMeasurements = ({ config }: UseAddMeasurementsOptions = {}) => {
    const { addNotification } = useNotificationStore();

    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries(['/measurements']);

            const previousMeasurements = queryClient.getQueryData(['/measurements']);
            queryClient.setQueryData('/measurements', previousMeasurements);

            return { previousMeasurements };
        },
        onError: (_, __, context: any) => {
            if (context?.previousMeasurements) {
                queryClient.setQueryData('/measurements', context.previousMeasurements);
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries('/measurements');
            addNotification({
                type: 'success',
                title: 'Merenje uspešno dodato.',
            });
        },
        ...config,
        mutationFn: addMeasurements,
    });
};
