import { useNavigate } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { LoginForm } from '../components/LoginForm';

export const Login = () => {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <Layout title="Ulaz">
        <LoginForm onSuccess={() => navigate('/app/patients')} />
      </Layout>
      <div className="bg-green"></div>
    </div>
  );
};
