import React from 'react';

type Props = {
  children?: JSX.Element | JSX.Element[];
};
export const ViewPatientInfoColumn = ({ children }: Props) => {
  return (
    <div className="flex flex-col max-w-[485px] h-auto pb-3 pt-1 rounded-[4px] bg-[#F3F3F3] mt-4 ml-[26px] overflow-auto	">
      {children}
    </div>
  );
};
