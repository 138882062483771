import { CreatingFolder } from '../../components/Documents/CreatingFolders';
import { DeleteFolder } from '../../components/Documents/DeleteFolder';
import { RenameFolder } from '../../components/Documents/RenameFolder';

import { DeleteFile } from './DeleteFile';
import { MoveFile } from './MoveFile';

export const TableHead = ({
  isFolder,
  activeIndex,
  isInEditMode,
  setIsInEditMode,
  id,
  directory,
  parentId,
  start,
}: any) => {
  return (
    <div className="flex px-3 bg-[#39433B] rounded-t-xl	text-[#FAFAFA] h-[72px] items-center">
      <p className="ml-4  text-[#FAFAFA] tracking-[0.25px] text-[18px] leading-[21px]">Dokumenta</p>
      {activeIndex !== 0 && (
        <>
          {isFolder && (
            <>
              <RenameFolder
                activeIndex={activeIndex}
                isInEditMode={isInEditMode}
                setIsInEditMode={setIsInEditMode}
              />
              <DeleteFolder activeIndex={activeIndex} />
            </>
          )}
          {!isFolder && (
            <>
              <DeleteFile activeIndex={activeIndex} />
              <MoveFile id={id} activeIndex={activeIndex} />
            </>
          )}
        </>
      )}
      <CreatingFolder id={id} directory={directory} parentId={parentId} start={start} />
    </div>
  );
};
