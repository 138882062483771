import { useDiseases } from '../api/getSearchData';

export const Diseases = ({ setSearchParam, searchParam, isPatientsPage }: any) => {
  const diseasesQuery: any = useDiseases();
  const keyName = isPatientsPage ? 'diseases.id' : 'medicalRecord.diseases.id';

  if (!diseasesQuery.data) return null;

  function sendDiseasesId(id: any, checked: any) {
    const newArr = [...searchParam[keyName]];
    newArr.push(id);
    if (checked) {
      setSearchParam({ ...searchParam, [`${keyName}`]: newArr, page: 1 });
    } else {
      setSearchParam({ ...searchParam, [`${keyName}`]: newArr.filter((e) => e !== id) });
    }
  }

  const diseasesList = diseasesQuery.data.data.map((diseases: any) => {
    return (
      <li key={diseases.id}>
        <label>
          <input
            type="checkbox"
            className="mr-2"
            id={diseases.id}
            checked={Object(searchParam[`${keyName}`]).includes(`${diseases.id}`)}
            onChange={(e) => {
              sendDiseasesId(e.target.id, e.target.checked);
            }}
          />
          {diseases.name}
        </label>
      </li>
    );
  });

  return <>{diseasesList}</>;
};
