import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';

import { Diets } from '../types';

export const getDiets = (): Promise<Diets[]> => {
  return axios.get(`/diets`);
};

export const getDiseases = (): Promise<Diets[]> => {
  return axios.get(`/diseases`);
};

export const getNutritions = (): Promise<Diets[]> => {
  return axios.get(`/users?filter[roles.name]=zaposleni,admin`);
};

type UseuseDietsOptions = {
  config?: QueryConfig<typeof getDiets>;
};

export const useDiets = ({ config }: UseuseDietsOptions = {}) => {
  return useQuery({
    ...config,
    queryKey: ['diets'],
    queryFn: () => getDiets(),
  });
};

export const useDiseases = ({ config }: UseuseDietsOptions = {}) => {
  return useQuery({
    ...config,
    queryKey: ['diseases'],
    queryFn: () => getDiseases(),
  });
};

export const useNutritions = ({ config }: UseuseDietsOptions = {}) => {
  return useQuery({
    ...config,
    queryKey: ['/users?filter[roles.name]=zaposleni,admin'],
    queryFn: () => getNutritions(),
  });
};
