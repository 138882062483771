import { useSubfiles } from '../../api/getDirectorySubdirectory';
import { Spinner } from '@/components/Elements';
import { Subfolders } from './Subfolders';
import { useEffect } from 'react';

export const Subfiles = ({ id, isInEditMode, setRenderSubfiles, handleOnClick, setParentId, path, setPath, setPathBack, setIsInEditMode, setActiveIndex, setStart, activeIndex, setFilesCount }: any) => {

    const subfiles: any = useSubfiles({ id });
    localStorage.setItem('directoryIdForUpload', id);

    useEffect(() => {
        if (subfiles.status === 'success') {
            setParentId(subfiles.data.data.parent_id)
            setPathBack(subfiles.data.data.name)
        }
    });

    if (subfiles.isLoading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        );
    }

    if (!subfiles) return null;

    return (
        <>
            <Subfolders
                directory={subfiles.data.data}
                activeIndex={activeIndex}
                isInEditMode={isInEditMode}
                setRenderSubfiles={setRenderSubfiles}
                handleOnClick={handleOnClick}
                path={path}
                setPath={setPath}
                setIsInEditMode={setIsInEditMode}
                setActiveIndex={setActiveIndex}
                setParentId={setParentId}
                setFilesCount={setFilesCount}
            />
        </>
    )
}