import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { Directories } from '../types';

export type MoveFileDTO = {
  id: number;
  directory_id: number;
};

export const moveFile = ({ id, directory_id }: MoveFileDTO) => {
  return axios.patch(`/files/${id}/move`, { directory_id: directory_id });
};

type UseMoveFileOptions = {
  config?: MutationConfig<typeof moveFile>;
};

export const useMoveFile = ({ config }: UseMoveFileOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('directories');

      const previousFolders = queryClient.getQueryData<Directories[]>('directories');

      return { previousFolders };
    },
    onError: (_, __, context: any) => {
      if (context?.previousFolders) {
        queryClient.setQueryData('directories', context.previousFolders);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('directories');
      addNotification({
        type: 'success',
        title: 'Fajl uspešno premesten',
      });
    },
    ...config,
    mutationFn: moveFile,
  });
};
