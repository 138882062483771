import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { Directories } from '../types';

export type AddFileDTO = {
  formData: any;
  param: any;
};

export const addFile = ({ formData, param }: AddFileDTO) => {
  return axios.post(`/files${param}`, formData);
};

type UseAddFileOptions = {
  config?: MutationConfig<typeof addFile>;
};

export const useAddFile = ({ config }: UseAddFileOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries(['directories']);

      const previousFolders = queryClient.getQueryData<Directories[]>(['directories']);
      queryClient.setQueryData('directories', previousFolders);

      return { previousFolders };
    },
    onError: (_, __, context: any) => {
      if (context?.previousFolders) {
        queryClient.setQueryData('directories', context.previousFolders);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('directories');
      addNotification({
        type: 'success',
        title: 'Fajl uspešno dodat',
      });
    },
    ...config,
    mutationFn: addFile,
  });
};
