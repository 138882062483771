import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

export type DeleteMeasurementsDTO = {
    measurementsIndex: number;
};

export const deleteMeasurements = ({ measurementsIndex }: DeleteMeasurementsDTO) => {
    return axios.delete(`/measurements/${measurementsIndex}`);
};

type UseDeleteMeasurementsOptions = {
    config?: MutationConfig<typeof deleteMeasurements>;
};

export const useDeleteMeasurements = ({ config }: UseDeleteMeasurementsOptions = {}) => {
    const { addNotification } = useNotificationStore();

    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries('/measurements');

            const previousMeasurements = queryClient.getQueryData('/measurements');

            queryClient.setQueryData('/measurements', previousMeasurements);

            return { previousMeasurements };
        },
        onError: (_, __, context: any) => {
            if (context?.previousMeasurements) {
                queryClient.setQueryData('/measurements', context.previousMeasurements);
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries('/measurements');
            addNotification({
                type: 'success',
                title: 'Merenje je obrisano!',
            });
        },
        ...config,
        mutationFn: deleteMeasurements,
    });
};
