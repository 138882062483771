import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';

import calendar from '@/assets/icons/calendar.png';

export const DatePicker = ({
  setSearchParam,
  searchParam,
  showDatePicker,
  setShowDatePicker,
}: any) => {
  const [date, setDate]: any = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ]);

  useEffect(() => {
    if (date[0].startDate && date[0].endDate) {
      const startDate = formatDate(date[0].startDate);
      const endDate = formatDate(date[0].endDate);
      if (startDate && endDate) {
        setSearchParam({ ...searchParam, start_date: startDate, end_date: endDate, page: 1 });
      }
    }
  }, [date]);

  function formatDate(datum: any) {
    const date = new Date(datum);
    const formatedDate = datum.getFullYear() + '-' + (datum.getMonth() + 1) + '-' + datum.getDate();
    return formatedDate;
  }

  return (
    <div className="relative">
      <button
        className="date-btn rounded bg-gray p-2.5 text-left"
        onClick={() => setShowDatePicker(!showDatePicker)}
      >
        <img className="inline-block pb-1 pr-2" src={calendar}></img>
        Pretraga po datumu
        <svg
          className="h-8 w-8 text-black inline-block float-right pb-1"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          {' '}
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </button>
      {showDatePicker && (
        <>
          <DateRange
            editableDateInputs={true}
            onChange={(item) => setDate([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={date}
            retainEndDateOnFirstSelection={true}
          />
        </>
      )}
    </div>
  );
};
