import { useDiets } from '../api/getSearchData';

export const AllFilterDietList = ({ searchParam, setSearchParam, isPatientsPage }: any) => {
  const keyName = isPatientsPage ? 'diets.id' : 'medicalRecord.diets.id';
  const filterDiet = searchParam[`${keyName}`];
  const dietsNameArr: any = [];
  const dietsQuery: any = useDiets();
  if (!dietsQuery) return null;

  if (dietsQuery.data) {
    filterDiet.map((id: any) => {
      dietsQuery?.data?.data.map((data: any) => {
        if (data.id === parseInt(id)) {
          dietsNameArr.push({ name: data.name, id: data.id });
        }
      });
    });
  }

  const allFilterDietList = dietsNameArr.map((data: any) => {
    return (
      <button
        className="bg-dark-green hover:opacity-20 text-white py-2 px-4 rounded mr-5 mb-5 text-sm"
        key={data.id}
        onClick={() => {
          removeDietFromFilter(data.id);
        }}
      >
        {data.name} <span>X</span>
      </button>
    );
  });

  function removeDietFromFilter(id: any) {
    const newArr = [...searchParam[`${keyName}`]];
    setSearchParam({ ...searchParam, [`${keyName}`]: newArr.filter((e) => e !== `${id}`) });
  }

  return <>{allFilterDietList}</>;
};
