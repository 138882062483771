import { Button, Spinner } from '@/components/Elements';
import { Notifications } from '@/components/Notifications/Notifications';
import { AuthProvider } from '@/lib/auth';
import { queryClient } from '@/lib/react-query';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { string } from 'zod';

const ErrorFallback = ({ error }: any) => {
  const [errosState, setErrosState] = React.useState<string[]>([]);
  React.useEffect(() => {
    const obj = JSON.parse(error?.response?.request?.response).errors;
    for (const k in obj) {
      const errorContent =
        k == 'login'
          ? 'Molim Vas da zatvorite ovaj prozor i onda opet kliknete na aktivacioni link. To bitrebalo da pokrene aplikaciju i omogući Vam nesmetano korišćenje.'
          : obj[k];
      setErrosState([...errosState, errorContent]);
    }

    console.log({
      errorMessage: JSON.parse(error?.response?.request?.response),
      agent: navigator.userAgent,
      language: navigator.language,
      cookiesEnabled: navigator.cookieEnabled,
      online: navigator.onLine,
      location: location.href,
    });
  }, []);

  React.useEffect(() => {
    console.log(errosState);
  }, [errosState]);

  return (
    <>
      <div className="w-screen h-screen flex flex-col justify-center items-center" role="alert">
        <div className="text-red-500 w-screen flex flex-col justify-center items-center">
          <h2 className="text-lg font-semibold">Došlo je do greške</h2>
          <p>{errosState?.map((errorItem: string) => errorItem)}</p>
          <Button className="mt-4" onClick={() => window.location.reload()}>
            Refresh
          </Button>
        </div>
        <br />
        <br />
        <div className="w-screen flex flex-col justify-center items-center" role="alert">
          <p> {error.message} </p>
          <p> error at: {location.href}</p>
        </div>
      </div>
    </>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner size="xl" />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error, errorInfo) => console.log({ error, errorInfo })}
      >
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
            <Notifications />
            <AuthProvider>
              <Router>{children}</Router>
            </AuthProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
