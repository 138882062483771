import { AllFilterCurrencyList } from './allFiltersComponent/AllFilterCurrrencyList';
import { AllFilterDatePicker } from './allFiltersComponent/AllFilterDatePicker';
import { AllFilterDietList } from './allFiltersComponent/AllFilterDietList';
import { AllFilterDiseasesList } from './allFiltersComponent/AllFilterDiseasesList';
import { AllFilterNutritionistsList } from './allFiltersComponent/AllFilterNutritionistsList';
import { AllFilterStatusList } from './allFiltersComponent/AllFilterStatusList';

export const AllFilters = ({ setSearchParam, searchParam, isAdmin, isPatientsPage }: any) => {
  let initialState = {};
  if (isPatientsPage) {
    initialState = {
      record_number: '',
      'user.name': '',
      status: [],
      nutritionist_id: [],
      'diets.id': [],
      'diseases.id': [],
    };
  }
  if (!isPatientsPage) {
    initialState = {
      'medicalRecord.record_number': '',
      'medicalRecord.user.name': '',
      'medicalRecord.status': [],
      'medicalRecord.nutritionist_id': [],
      'medicalRecord.diets.id': [],
      'medicalRecord.diseases.id': [],
      currency: [],
      start_date: '',
      end_date: '',
    };
  }

  function filterReset() {
    localStorage.removeItem('searchParam');
    setSearchParam({ ...initialState });
  }

  return (
    <>
      <AllFilterStatusList
        setSearchParam={setSearchParam}
        searchParam={searchParam}
        isPatientsPage={isPatientsPage}
      />
      <AllFilterDietList
        setSearchParam={setSearchParam}
        searchParam={searchParam}
        isPatientsPage={isPatientsPage}
      />
      <AllFilterDiseasesList
        setSearchParam={setSearchParam}
        searchParam={searchParam}
        isPatientsPage={isPatientsPage}
      />
      {isAdmin && (
        <>
          <AllFilterNutritionistsList
            setSearchParam={setSearchParam}
            searchParam={searchParam}
            isAdmin={isAdmin}
            isPatientsPage={isPatientsPage}
          />
          {!isPatientsPage && (
            <>
              <AllFilterCurrencyList setSearchParam={setSearchParam} searchParam={searchParam} />
              <AllFilterDatePicker setSearchParam={setSearchParam} searchParam={searchParam} />
            </>
          )}
        </>
      )}
      <button
        className="bg-green hover:opacity-20 text-white py-2 px-4 mr-5 rounded mb-3 text-sm"
        onClick={() => {
          filterReset();
        }}
      >
        reset filtera
      </button>
    </>
  );
};
