import { DocumentDuplicateIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useDoubleTap } from 'use-double-tap';

import fileImg from '@/assets/fileImg.png';
import { axios } from '@/lib/axios';
import { formatDate } from '@/utils/format';

const Documents = () => {
  const [files, setFiles] = useState([]);
  useEffect(() => {
    axios.get(`/users/patient/me?uuid=${localStorage.getItem('uuid')}`).then((res) => {
      setFiles(res.data.medicalRecord.files);
    });
  }, []);
  const [activeFile, setActiveFile]: any = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      setIsMobile(true);
    }
  });

  const bind = useDoubleTap((e) => {
    if (!isMobile) {
      const target = e.target as HTMLTextAreaElement;
      const id = target.dataset.id;
      const name = target.dataset.name;
      downloadFile(id, name);
    }
  });

  const FileDownload = require('js-file-download');
  function downloadFile(id: any, name: any) {
    axios({
      url: `patient/files/${id}/download?uuid=${localStorage.getItem('uuid')}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response: any) => {
      FileDownload(response, name);
    });
  }

  const fileList = files.map((file: any, index: any) => {
    return (
      <div
        {...bind}
        className={
          activeFile === file.id
            ? 'activeFolder bg-light-green flex min-h-12 p-[14px] rounded-xl select-none cursor-pointer text-sm text-[#4A4A4A] file-holder'
            : 'text-sm text-[#4A4A4A] flex bg-column-lightgray min-h-12 p-[14px] rounded-xl select-none cursor-pointer file-holder'
        }
        key={file.id}
        id={file.id}
        data-id={file.id}
        data-name={file.name}
        onMouseDown={() => {
          !isMobile ? setActiveFile(file.id) : downloadFile(file.id, file.name);
        }}
      >
        <div className="ml-5 flex items-center" data-id={file.id} data-name={file.name}>
          <img
            className="pb-1 inline-block h-[20px]"
            src={fileImg}
            data-id={file.id}
            data-name={file.name}
          ></img>
          <span
            style={{
              wordBreak: 'break-word',
              padding: '14px 0px 14px 8px',
            }}
            className="contact-link"
            data-id={file.id}
            data-name={file.name}
          >
            {file.name}
          </span>
        </div>
        <div className="ml-auto px-5 flex items-center" data-id={file.id} data-name={file.name}>
          {formatDate(file.updated_at)}
        </div>
      </div>
    );
  });

  return (
    <div className="flex flex-col w-fit h-fit rounded-xl">
      <div className="flex px-3 bg-[#39433B] rounded-t-xl	text-[#FAFAFA] h-[72px] items-center">
        <DocumentDuplicateIcon width={18} />
        <p className="ml-3 text-[#FAFAFA] tracking-[0.25px] text-[18px] leading-[21px]">
          Dokumenta
        </p>
      </div>

      <div className="bg-white p-3 rounded-b-xl documents-list">{files && fileList}</div>
    </div>
  );
};

export default Documents;
