import arrowBack from '@/assets/arrow-back.png';
import { useEffect } from 'react';

export const DocumentNavigation = ({ state, parentId, setRenderSubfiles, path, setPath, pathBack, setStart }: any) => {

    function setStartStatus(state: boolean) {
        setStart(state);
    }

    useEffect(() => {
        if (path.length === 0)
            setStartStatus(true)
        if (path.length > 0)
            setStartStatus(false)

    }, [path]);

    return (
        <div className="flex flex-col border-b-2 border-[#D8D8D8]">
            <div className="h-[54px] leading-[54px] px-4 bg-white text-[#4A4A4A]">
                {path.length > 0 && (
                    <button onClick={
                        () => (setRenderSubfiles({ show: true, id: parentId },
                            setPath(path.filter((e: any) => e !== pathBack)))
                        )
                    }>
                        <img className="pr-2 inline-block" src={arrowBack}></img>
                    </button>
                )}
                <span className="">{state.user.name}</span>
                {path.length > 0 && (
                    <span className="ml-1 mr-1">{' > '}</span>
                )}
                {path.map(function (x: any) { return x.charAt(0).toUpperCase() + x.slice(1) }).join(' > ')}
            </div>
        </div>
    )
}