import { useQuery } from 'react-query';
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { MedicalRecord } from '../types';

export const getSubfilesRecord = ({ id }: { id: string }): Promise<MedicalRecord[]> => {
    return axios.get(`/directories/${id}`);
};

type UseMedicalRecordOptions = {
    id: string;
    config?: QueryConfig<typeof getSubfilesRecord>;
};

export const useSubfiles = ({ id, config }: UseMedicalRecordOptions) => {
    return useQuery({
        ...config,
        queryKey: ['directories', id],
        queryFn: () => getSubfilesRecord({ id }),
    });
};


