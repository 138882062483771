import { useMutation } from 'react-query';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { Directories } from '../types';

export type RenameFolderDTO = {
    activeIndex: number;
    name: string;
};

export const renameFolder = ({ activeIndex, name }: RenameFolderDTO) => {
    return axios.patch(`/directories/${activeIndex}/rename`, { name: name });
};

type UseRenameFolderOptions = {
    config?: MutationConfig<typeof renameFolder>;
};

export const useRenameFolder = ({ config }: UseRenameFolderOptions = {}) => {

    return useMutation({
        onMutate: async () => {
            await queryClient.cancelQueries('directories');

            const previousFolders = queryClient.getQueryData<Directories[]>('directories');

            return { previousFolders };
        },
        onError: (_, __, context: any) => {
            if (context?.previousFolders) {
                queryClient.setQueryData('directories', context.previousFolders);
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries('directories');
        },
        ...config,
        mutationFn: renameFolder,
    });
};

