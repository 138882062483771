import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { Directories } from '../types';

export type DeleteFolderDTO = {
  activeIndex: number;
};

export const deleteFolder = ({ activeIndex }: DeleteFolderDTO) => {
  return axios.delete(`/directories/${activeIndex}`);
};

type UseDeleteFolderOptions = {
  config?: MutationConfig<typeof deleteFolder>;
};

export const useDeleteFolder = ({ config }: UseDeleteFolderOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (deletedFolder) => {
      await queryClient.cancelQueries('directories');

      const previousFolders = queryClient.getQueryData<Directories[]>('directories');

      queryClient.setQueryData(
        'directories',
        previousFolders?.filter((directorie) => directorie.id !== deletedFolder.activeIndex)
      );

      return { previousFolders };
    },
    onError: (_, __, context: any) => {
      if (context?.previousFolders) {
        queryClient.setQueryData('directories', context.previousFolders);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('directories');
      addNotification({
        type: 'success',
        title: 'Folder uspešno obrisan',
      });
    },
    ...config,
    mutationFn: deleteFolder,
  });
};
