import arrowRight from '@/assets/icons/arrow-right.png';
import filter from '@/assets/icons/filter.png';

import { Currency } from './dropdownComponents/Currency';
import { Diets } from './dropdownComponents/Diets';
import { Diseases } from './dropdownComponents/Diseases';
import { Nutritionist } from './dropdownComponents/Nutritionists';
import { Status } from './dropdownComponents/Status';

export const FilterDropdown = ({ setSearchParam, searchParam, isAdmin, isPatientsPage }: any) => {
  return (
    <div className="relative mr-6">
      <ul className="dropdown shadow rounded">
        <li>
          <label className="text-left">
            <img className="inline-block" src={filter}></img> Filteri{' '}
            <svg
              className="h-8 w-8 text-black inline-block float-right pb-1"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              {' '}
              <polyline points="6 9 12 15 18 9" />
            </svg>
          </label>
          <ul>
            <li>
              <label>
                Faza <img className="inline-block float-right" src={arrowRight}></img>{' '}
              </label>
              <ul>
                <Status
                  searchParam={searchParam}
                  setSearchParam={setSearchParam}
                  isPatientsPage={isPatientsPage}
                />
              </ul>
            </li>
            <li>
              <label>
                Tip Dijete <img className="inline-block float-right" src={arrowRight}></img>
              </label>
              <ul>
                <Diets
                  searchParam={searchParam}
                  setSearchParam={setSearchParam}
                  isPatientsPage={isPatientsPage}
                />
              </ul>
            </li>
            <li>
              <label>
                Tip Oboljenja <img className="inline-block float-right " src={arrowRight}></img>
              </label>
              <ul>
                <Diseases
                  searchParam={searchParam}
                  setSearchParam={setSearchParam}
                  isPatientsPage={isPatientsPage}
                />
              </ul>
            </li>
            {isAdmin && (
              <>
                <li>
                  <label>
                    Nutricionista <img className="inline-block float-right" src={arrowRight}></img>
                  </label>
                  <ul>
                    <Nutritionist
                      searchParam={searchParam}
                      setSearchParam={setSearchParam}
                      isAdmin={isAdmin}
                      isPatientsPage={isPatientsPage}
                    />
                  </ul>
                </li>
                {!isPatientsPage && (
                  <li>
                    <label>
                      Valuta <img className="inline-block float-right" src={arrowRight}></img>
                    </label>
                    <ul>
                      <Currency searchParam={searchParam} setSearchParam={setSearchParam} />
                    </ul>
                  </li>
                )}
              </>
            )}
          </ul>
        </li>
      </ul>
    </div>
  );
};
