export const DateAndTimePicker = ({ setTimeForSend, setDateForSend }: any) => {

    const min = new Date().toISOString().split("T")[0];

    return (
        <>
            <p className="my-4">Izabrati vreme slanja</p>
            <input
                className="formInput rounded"
                type="date"
                min={min}
                defaultValue={min}
                onChange={(e) => setDateForSend(e.target.value)}
            ></input>

            <input
                className="formInput rounded ml-5"
                type="time"
                id="appt"
                name="appt"
                defaultValue="12:00"
                min="09:00:00.00" max="18:00"
                required
                onChange={(e) => setTimeForSend(e.target.value)}
            ></input>
        </>
    )
}