import { Link } from 'react-router-dom';

import { Table } from '@/components/Elements';

import { Patient } from '../types';

export const PatientsAdmin = ({ usersQuery }: any) => {
  return (
    <Table<Patient>
      data={usersQuery.data.data}
      total={'Za trenutno podešene filtere ukupno: ' + usersQuery.data.meta.total + ' pacijenata'}
      columns={[
        {
          title: 'ID',
          field: 'record_number',
        },
        {
          title: 'Ime i prezime',
          field: 'name',
          Cell({
            entry: { user },
            entry: { record_number },
            entry: { status },
            entry: { nutritionist },
            entry: { diets },
            entry: { diseases },
            entry: { note },
            entry: { age },
            entry: { date_of_birth },
            entry: { payments },
            entry: { id },
            entry: { height },
            entry: { date_of_first_arrival },
            entry: { directory },
            entry: { files_count },
          }) {
            const patientData: any = {
              user: user,
              record_number: record_number,
              status: status,
              nutritionist: nutritionist,
              diets: diets,
              diseases: diseases,
              note: note,
              age: age,
              date_of_birth: date_of_birth,
              payments: payments,
              id: id,
              height: height,
              date_of_first_arrival: date_of_first_arrival,
              directory: directory,
              files_count: files_count,
            };
            return (
              <span>
                <Link
                  state={patientData}
                  className="text-blue-link underline"
                  to={'../patients-card?name=' + user.name.replace(/\s/g, '')}
                  onContextMenu={() =>
                    localStorage.setItem(
                      `referrer-${user.name.replace(/\s/g, '')}`,
                      JSON.stringify(patientData)
                    )
                  }
                >
                  {user.name}
                </Link>
              </span>
            );
          },
        },
        {
          title: 'Telefon',
          field: 'phone',
          Cell({ entry: { user } }) {
            return <div className="whitespace-normal">{user.phone}</div>;
          },
        },
        {
          title: 'Email',
          field: 'email',
          Cell({ entry: { user } }) {
            return <div className="whitespace-normal">{user.email}</div>;
          },
        },
        {
          title: 'Faza',
          field: 'status',
          Cell({ entry: { status } }: any) {
            return (
              <span className={`status status-${status}`}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </span>
            );
          },
        },
        {
          title: 'Tip dijete',
          field: 'diets',
          Cell({ entry: { diets } }) {
            const dietsName: Array<string> = [];
            if (diets instanceof Array) {
              diets.map((diet: any) => {
                dietsName.push(diet.name);
              });
            }
            return <div className="whitespace-normal">{dietsName.join(',')}</div>;
          },
        },
        {
          title: 'Tip oboljenja',
          field: 'diseases',
          Cell({ entry: { diseases } }) {
            const diseasesName: Array<string> = [];
            diseases.map((disease: any) => {
              diseasesName.push(disease.name);
            });
            return <div className="whitespace-normal">{diseasesName.join(',')}</div>;
          },
        },
        {
          title: 'Nutricionista',
          field: 'nutritionist',
          Cell({ entry: { nutritionist } }: any) {
            return <div id={nutritionist.id}>{nutritionist.name}</div>;
          },
        },
      ]}
    />
  );
};
