import { Spinner } from '@/components/Elements';

import { AppointmentProps } from '../types';

import { DateCard } from './TerminCard';

type DeleteModalProps = {
  appointment?: AppointmentProps | null;
  closeModal: () => void;
  deleteAppointmentHandler: (a: AppointmentProps | null | undefined) => void;
  isLoading?: boolean;
  isError?: boolean;
  error?: any;
};

type ChangeModalProps = {
  appointment?: AppointmentProps | null;
  closeModal: () => void;
  changeAppointmentHandler: (a: AppointmentProps | null | undefined) => void;
  isLoading?: boolean;
  isError?: boolean;
  error?: any;
};

export const DeleteModal = ({
  appointment,
  closeModal,
  deleteAppointmentHandler,
  isLoading,
  isError,
  error,
}: DeleteModalProps) => {
  return (
    <div>
      <h2 className="font-medium text-[18px] text-dark-gray mb-6">Otkazivanje pregleda</h2>
      <p className="leading-6 text-dark-gray mb-4">
        Da li ste sigurni da želite da uklonite termin pregleda?
      </p>
      <div className="pb-3 border-b border-[#F1F1F1] mb-6">
        <DateCard timeDate={appointment} />
      </div>
      <div className="flex justify-center">
        {isLoading && <Spinner />}
        {isError && (
          <p className="text-red-600 py-2 text-center">
            {error?.response?.data?.message || 'Došlo je do greške'}
          </p>
        )}
      </div>
      <div className="flex justify-end space-x-[18px] confirmation-dialogs-btn-holder">
        <button
          className="px-[30px] tracking-wider h-[48px] rounded-xl text-dark-gray border-none"
          onClick={closeModal}
        >
          Odustani
        </button>
        <button
          className="px-[30px] tracking-wider h-[48px] rounded-xl text-white my-bg-green leading-none"
          onClick={() => deleteAppointmentHandler(appointment)}
        >
          Potvrdi otkazivanje
        </button>
      </div>
    </div>
  );
};

export const ChangeModal = ({
  appointment,
  closeModal,
  changeAppointmentHandler,
  isLoading,
  isError,
  error,
}: ChangeModalProps) => {
  return (
    <div>
      <h2 className="font-medium text-[18px] text-dark-gray mb-6">Potvrdi izmenu</h2>
      <p className="leading-6 text-dark-gray mb-4">
        Da li ste sigurni da želite da promenite termin pregleda?
      </p>
      <div className="pb-3 border-b border-[#F1F1F1] mb-6">
        <DateCard timeDate={appointment} />
      </div>
      <div className="flex justify-center">
        {isLoading && <Spinner />}
        {isError && (
          <p className="text-red-600 py-2 text-center">
            {error?.response?.data?.errors?.start[0] || error?.response?.data?.message || 'Došlo je do greške'}
          </p>
        )}
      </div>
      <div className="flex justify-end space-x-[18px]">
        <button
          className="px-[30px] tracking-wider h-[48px] rounded-xl text-dark-gray border-none"
          onClick={closeModal}
        >
          Otkaži
        </button>
        <button
          className="px-[30px] tracking-wider h-[48px] rounded-xl text-white my-bg-green"
          onClick={() => changeAppointmentHandler(appointment)}
        >
          Potvrdi
        </button>
      </div>
    </div>
  );
};
