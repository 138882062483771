import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { Payments } from '../../PatientCard/types';

export type DeletePaymentDTO = {
  activeIndex: number;
};

export const deletePayment = ({ activeIndex }: DeletePaymentDTO) => {
  return axios.delete(`/payments/${activeIndex}`);
};

type UseDeletePaymentOptions = {
  config?: MutationConfig<typeof deletePayment>;
};

export const useDeletePayment = ({ config }: UseDeletePaymentOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('payments');

      const previousPayments = queryClient.getQueryData<Payments[]>('payments');

      queryClient.setQueryData('payments', previousPayments);

      return { previousPayments };
    },
    onError: (_, __, context: any) => {
      if (context?.previousPayments) {
        queryClient.setQueryData('payments', context.previousPayments);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('payments');
      addNotification({
        type: 'success',
        title: 'Uplata je obrisana!',
      });
    },
    ...config,
    mutationFn: deletePayment,
  });
};
