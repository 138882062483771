import { ChangePswForm } from '../components/ChangePswForm';
import { Layout } from '../components/Layout';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';

export const ChangePassword = () => {
  const { addNotification } = useNotificationStore();

  function sendEmail(email: string) {
    axios({
      url: `/password/forgot`,
      method: 'POST',
      data: { email: email }
    }).then((response: any) => {
      addNotification({
        type: 'success',
        title: response.message
      });
    })
  }

  return (
    <Layout title="Zaboravili ste lozinku">
      <p className="mb-5">Unesite email adresu koja je povezana sa vašim nalogom</p>
      <ChangePswForm onSuccess={(e) => sendEmail(e.email)} />
    </Layout>
  );
};
