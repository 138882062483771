import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { Payment } from '@/features/payments/types';
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';

export const getPayments = ({ record_number_id }: any): Promise<Payment[]> => {
  return axios.get(`/payments/medical-records/${record_number_id}`);
};

type UsePaymentsOptions = {
  config?: QueryConfig<typeof getPayments>;
  record_number_id: string;
};

export const usePayments = ({ config, record_number_id }: UsePaymentsOptions) => {
  return useQuery({
    ...config,
    queryKey: ['payments', record_number_id],
    queryFn: () => getPayments({ record_number_id }),
  });
};
