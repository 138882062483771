import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useMatch } from 'react-router-dom';
import Select from 'react-select';

import { useAuth } from '@/lib/auth';
import { ROLES } from '@/lib/authorization';
import { axios } from '@/lib/axios';
import { useNotificationStore } from '@/stores/notifications';

import save from '../../../assets/icons/save.png';
import DeleteButton from '../DeleteButton/DeleteButton';
import { useDiets, useDiseases, useNutritions } from '../SearchBar/api/getSearchData';

export const EnterPatientInfo = () => {
  const { addNotification } = useNotificationStore();
  let { state }: any = useLocation();
  const match = useMatch({ path: 'app/patients-card', end: true });
  const url_string = window.location.href;
  const url = new URL(url_string);
  const name = url.searchParams.get('name');

  const retrievedObject = localStorage.getItem(`referrer-${name}`);
  if (state === null && match && retrievedObject) {
    state = retrievedObject ? JSON.parse(retrievedObject) : null;
  }
  const { user } = useAuth();
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const statusOptions = [
    { value: 'Aktivan', label: 'Aktivan' },
    { value: 'Održavanje', label: 'Održavanje' },
    { value: 'Mirovanje', label: 'Mirovanje' },
  ];

  const [selectedNutritionist, setSelectedNutritionist]: any = useState(null);
  const [selectedStatus, setSelectedStatus]: any = useState({ value: 'Aktivan', label: 'Aktivan' });
  const [patientAge, setPatientAge]: any = useState('');

  const dietsContainer = document.querySelector('.Diets');
  const nutritionistOptions: Array<any> = [];
  const dietsQuery: any = useDiets();
  const diseasesQuery: any = useDiseases();
  const nutritionsQuery: any = useNutritions();
  nutritionsQuery?.data?.data.map((nutritionist: any) => {
    nutritionistOptions.push({ value: parseInt(nutritionist.id), label: nutritionist.name });
  });
  const navigate = useNavigate();

  //Select styles

  const [statusStyles, setStatusStyles] = useState({});

  const [nutricionistStyles, setNutricionistStyles] = useState({});

  //Managing CheckBoxes

  const getChecboxValues = (checkbox_id: any) => {
    const arr: any = [];
    const cbArr: any = document.querySelectorAll('#' + checkbox_id);
    cbArr.forEach((cb: any) => {
      if (cb.checked) arr.push(parseInt(cb.value));
    });
    return arr;
  };

  const setChecboxValues = (arr: any, checkbox_id: any) => {
    const cbArr: any = document.querySelectorAll('#' + checkbox_id);
    cbArr.forEach((cb: any) => {
      for (let i = 0; i < arr.length; i++) {
        if (cb.value == arr[i].id) cb.checked = true;
      }
    });
    return arr;
  };

  //Setting the values onMount

  useEffect(() => {
    if (state) {
      const today = new Date();
      const bday = new Date(state.date_of_birth);
      console.log(state.date_of_first_arrival);
      setValue('record_number', state.record_number);
      setValue('name', state.user.name);
      setValue('email', state.user.email);
      setValue('phone', state.user.phone);
      setValue('height', state.height);
      setValue('date_of_birth', state.date_of_birth);
      setPatientAge(Math.floor((today.getTime() - bday.getTime()) / 31557600000));
      setSelectedStatus({
        value: state.status,
        label: state.status.charAt(0).toUpperCase() + state.status.slice(1),
      });
      setSelectedNutritionist({
        value: state.nutritionist.id,
        label: state.nutritionist.name,
      });
      setValue('note', state.note);
      setChecboxValues(state.diets, 'diet');
      setChecboxValues(state.diseases, 'diseases');
    }
  }, []);

  //Input validation

  const validateInput = () => {
    if (!checkForEmpty()) {
      const body = {
        record_number: getValues('record_number'),
        name: getValues('name'),
        date_of_birth: getValues('date_of_birth'),
        age: parseInt(patientAge),
        email: getValues('email'),
        phone: getValues('phone'),
        height: getValues('height'),
        status: selectedStatus?.value,
        nutritionist_id: selectedNutritionist?.value,
        note: getValues('note'),
        diet_ids: getChecboxValues('diet'),
        disease_ids: getChecboxValues('diseases'),
      };
      if (state)
        axios.put('/medical-records/' + state.id, body).then(() => {
          addNotification({
            type: 'success',
            title: 'Izmene su uspešno sacuvane',
          });
          navigate('/');
        });
      else
        axios.post('/medical-records', body).then(() => {
          addNotification({
            type: 'success',
            title: 'Pacijent ' + getValues('record_number') + ' je uspešno dodat',
          });
          navigate('/');
        });
    }
  };

  const checkForEmpty = () => {
    let isEmpty = false;
    const fieldsToCheck = document.querySelectorAll('#validate');
    fieldsToCheck.forEach((field: any) => {
      if (!field.value) {
        isEmpty = true;
        field.className = 'formInput inputError rounded';
      } else field.className = 'formInput';
    });
    if (!selectedStatus) {
      isEmpty = true;
      setStatusStyles({
        control: (base: any) => ({
          ...base,
          border: '1px solid rgb(204, 63, 63)',
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid rgb(204, 63, 63)',
          },
        }),
      });
    } else
      setStatusStyles({
        control: (base: any) => ({
          ...base,
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid gray',
          },
        }),
      });
    if (!selectedNutritionist) {
      isEmpty = true;
      setNutricionistStyles({
        control: (base: any) => ({
          ...base,
          border: '1px solid rgb(204, 63, 63)',
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid rgb(204, 63, 63)',
          },
        }),
      });
    } else
      setNutricionistStyles({
        control: (base: any) => ({
          ...base,
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid gray',
          },
        }),
      });
    if (getChecboxValues('diet').length == 0) {
      isEmpty = true;
      dietsContainer!.className = 'Diets border border-solid inputError rounded mr-6 px-4 py-2';
    } else
      dietsContainer!.className = 'Diets border border-solid border-gray rounded mr-6 px-4 py-2';
    if (isEmpty)
      addNotification({
        type: 'error',
        title: 'Oznacena polja moraju biti popunjena',
      });
    if (getChecboxValues('diseases').length == 0) {
      const cbArr: any = document.querySelectorAll('#diseases');
      cbArr[0].checked = true;
    } else {
      const cbArr: any = document.querySelectorAll('#diseases');
      if (getChecboxValues('diseases').length > 1 && cbArr[0].checked == true)
        cbArr[0].checked = false;
    }
    return isEmpty;
  };

  return (
    <div className="flex flex-col w-full h-fit  rounded-xl">
      <div className="flex px-3 bg-[#39433B] rounded-t-xl	text-[#FAFAFA] h-[72px] items-center">
        <p className="ml-4 text-[#FAFAFA] tracking-[0.25px] text-[18px] leading-[21px]">
          Unos Informacija
        </p>

        <div className="ml-auto mr-[16px] flex">
          {state && <DeleteButton patientId={state.id} />}
          <button
            className=" w-28 px-4 h-11 bg-[#35A6F9] text-[#FAFAFA] flex items-center cursor-pointer rounded"
            onClick={() => {
              validateInput();
            }}
          >
            <img src={save} className="mr-2 mb-[4px] h-4" alt="saveIcon" />
            <span>Sačuvaj</span>
          </button>
        </div>
      </div>
      <form className="flex p-[20px] bg-[#FFFFFF]">
        <div className="flex flex-col w-fill mr-6">
          <div className="flex">
            <span className="flex flex-col w-[90px] mr-[12px]">
              <label className="mb-[4px] text-xs" htmlFor="Id">
                ID
              </label>
              <input
                disabled={user?.roles[0].name === ROLES.ADMIN ? false : true}
                className={
                  errors.record_number ? 'formInput rounded inputError' : 'formInput rounded'
                }
                id="validate"
                type="text"
                placeholder="00000"
                maxLength={20}
                {...register('record_number', {
                  required: "This field can't be empty",
                })}
              ></input>
            </span>
            <span className="flex flex-col w-[398px]">
              <label className="mb-[4px] text-xs" htmlFor="Id">
                Ime i prezime
              </label>
              <input
                minLength={2}
                maxLength={60}
                className="formInput rounded"
                type="text"
                placeholder="Aleksandar Aleksandrovic"
                {...register('name')}
              ></input>
            </span>
          </div>
          <div className="flex">
            <span className="flex flex-col w-[420px]">
              <label className="mb-[4px] text-xs" htmlFor="Id">
                Datum rođenja*
              </label>
              <input
                className="formInput rounded"
                type="date"
                min="2000-11-2"
                {...register('date_of_birth', {
                  onChange: (e) => {
                    const today = new Date();
                    const bday = new Date(e.target.value);
                    setPatientAge(Math.floor((today.getTime() - bday.getTime()) / 31557600000));
                  },
                })}
              ></input>
            </span>
            {patientAge && (
              <span className="flex items-center mt-2 ml-8 text-lg">{patientAge + ' g'}</span>
            )}
          </div>
          <span className="flex flex-col w-[500px] ">
            <label className="mb-[4px] text-xs" htmlFor="Id">
              Visina
            </label>
            <input
              maxLength={20}
              // id="validate"
              className="formInput rounded"
              type="text"
              placeholder="Visina"
              {...register('height')}
            ></input>
          </span>
          <div className="flex">
            <span className="flex flex-col w-[500px]">
              <label className="mb-[4px] text-xs" htmlFor="Id">
                Email*
              </label>
              {user?.roles[0].name === ROLES.ADMIN ? (
                <input
                  maxLength={50}
                  id="validate"
                  className="formInput rounded"
                  type="email"
                  placeholder="Email"
                  {...register('email')}
                ></input>
              ) : (
                <div className="formInput bg-gray rounded"></div>
              )}
            </span>
          </div>
          <span className="flex flex-col w-[500px] ">
            <label className="mb-[4px] text-xs" htmlFor="Id">
              Broj mobilnog telefona*
            </label>
            {user?.roles[0].name === ROLES.ADMIN ? (
              <input
                maxLength={20}
                id="validate"
                className="formInput rounded"
                type="text"
                placeholder="Mobilni"
                {...register('phone')}
              ></input>
            ) : (
              <div className="formInput bg-gray"></div>
            )}
          </span>

          <span className="flex flex-col w-[500px]">
            <label className="mb-[4px] text-xs" htmlFor="Id">
              Faza
            </label>
            <Select
              value={selectedStatus}
              id="status"
              styles={statusStyles}
              onChange={setSelectedStatus}
              className="inputError"
              options={statusOptions}
              placeholder="Selektuj"
            />
          </span>
          <span className="flex flex-col w-[500px] mt-[12px]">
            <label className="mb-[4px] text-xs" htmlFor="Id">
              Zaduženi nutricionista
            </label>
            <Select
              isDisabled={user?.roles[0].name === ROLES.ADMIN ? false : true}
              value={selectedNutritionist}
              styles={nutricionistStyles}
              onChange={setSelectedNutritionist}
              options={nutritionistOptions}
              id="nutricionist"
              placeholder="Selektuj"
            />
          </span>
          <span className="flex flex-col w-[500px] mt-[12px]">
            <label className="mb-[4px] text-xs" htmlFor="Id">
              Napomena
            </label>
            <textarea
              maxLength={256}
              className="w-[500px] h-[120px] rounded"
              placeholder="Napomena..."
              rows={5}
              {...register('note')}
            ></textarea>
          </span>
        </div>
        <div className="border-r border-solid border-gray mr-6"></div>
        <div>
          <label className="mb-[4px] text-xs" htmlFor="Id">
            Tip dijete
          </label>
          <ul className="Diets border border-solid border-gray rounded mr-6 px-4 py-2">
            {dietsQuery?.data?.data.map((diet: any) => {
              return (
                <li className="mb-4 last:mb-0" key={diet.id}>
                  <label className="cursor-pointer">
                    <input
                      defaultChecked={state?.diets?.some((e: any) => e.id === diet.id) && true}
                      className="mr-2 h-4 w-4 cursor-pointer"
                      type="checkbox"
                      id="diet"
                      value={diet.id}
                    />
                    {diet.name}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <label className="mb-[4px] text-xs" htmlFor="Id">
            Tip oboljenja
          </label>
          <ul className="Diseases border border-solid  border-gray rounded px-4 py-2">
            {diseasesQuery?.data?.data.map((diseases: any) => {
              return (
                <li className={diseases.id == '1' ? 'hidden' : 'mb-4 last:mb-0'} key={diseases.id}>
                  <label className="cursor-pointer">
                    <input
                      defaultChecked={
                        state?.diseases?.some((e: any) => e.id === diseases.id) ? true : false
                      }
                      className="mr-2 h-4 w-4 cursor-pointer"
                      type="checkbox"
                      id="diseases"
                      value={diseases.id}
                    />
                    {diseases.name}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      </form>
    </div>
  );
};
