import { useRef, useEffect, useState } from 'react';
import { TypeDocument } from './TypeDocuments';
import { useAddFile } from '../PatientCard/api/addDocuments';
import dayjs from 'dayjs';

export const AddDocuments = ({ state }: any) => {

    const uploadInput = useRef<HTMLInputElement>(null);
    localStorage.setItem('addDocumentsState', JSON.stringify(state));
    if (state.state === null) {
        var retrievedObject: any = localStorage.getItem("addDocumentsState");
        retrievedObject = JSON.parse(retrievedObject);
        state = retrievedObject;
    }

    const min = new Date().toISOString().split("T")[0];
    const [dateForSend, setDateForSend] = useState(dayjs(min).format('YYYY-MM-DD'));
    const [timeForSend, setTimeForSend] = useState('12:00');
    const idForUpload = state.state ? state?.state?.id : state.id;
    const directoryIdforSub = localStorage.getItem('directoryIdForUpload');
    var directoryIdForUpload = directoryIdforSub ? directoryIdforSub : state.state ? state?.state?.directory.id : state.directory.id;
    const [documentType, setDocumentType] = useState('jelovnik');
    const addFileMutation: any = useAddFile();
    const [sendOption, setSendOption] = useState(true);

    useEffect(() => {
        const unloadCallback = () => {
            localStorage.removeItem('directoryIdForUpload');
        };

        window.addEventListener('beforeunload', unloadCallback);
        return () => window.removeEventListener('beforeunload', unloadCallback);
    }, []);

    const uploadFile = () => {
        if (uploadInput.current && documentType) {
            uploadInput.current.click();
        }
    }

    const onChange = (e: any) => {
        var files = e.target.files;
        var formData = new FormData();
        formData.append('type', documentType.toLowerCase());
        formData.append('medical_record_id', idForUpload);
        formData.append('directory_id', directoryIdForUpload);
        formData.append('file', files[0]);
        if (documentType === 'jelovnik' && !sendOption) {
            addFileMutation.mutate({ formData: formData, param: `?schedule_time=${dateForSend} ${timeForSend}` })
        } else {
            addFileMutation.mutate({ formData: formData, param: '' })
        }
        e.target.value = '';
        setDocumentType('jelovnik')
    }

    return (
        <div className="cursor-pointer">
            <TypeDocument
                setDocumentType={setDocumentType}
                uploadFile={uploadFile}
                addFileMutation={addFileMutation}
                setTimeForSend={setTimeForSend}
                setDateForSend={setDateForSend}
                sendOption={sendOption}
                setSendOption={setSendOption}
            />
            <input type="file" className="hidden"
                ref={uploadInput}
                onChange={(e) => { onChange(e) }}
                id="file" name="file"
                accept=".xml, .docx, .doc, .pdf, .txt, .rtf, .xls, .xlsx, .xlsm, .xlsb, .xlam, .csv, .jpeg, .jpg, .png" />
        </div>
    )
}