import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Spinner } from '@/components/Elements';

import { useMedicalRecord } from './api/getDocumentData';
import { DocumentNavigation } from './components/Documents/DocumentNavigation';
import { Folders } from './components/Documents/Folders';
import { Subfiles } from './components/Documents/Subfiles';
import { TableHead } from './components/Documents/TableHead';

export const PatientsDocument = ({ setFilesCount }: any) => {
  let { state }: any = useLocation();
  const url_string = window.location.href;
  const url = new URL(url_string);
  const name = url.searchParams.get('name');

  if (state === null) {
    const retrievedObject = localStorage.getItem(`referrer-${name}`);
    state = retrievedObject ? JSON.parse(retrievedObject) : null;
  }
  const userId = state.directory.id;
  const id = state.id;
  const medicalRecords: any = useMedicalRecord({ userId });
  const [activeIndex, setActiveIndex] = useState(0);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isFolder, setIsFolder] = useState(false);
  const [parentId, setParentId] = useState(0);
  const [renderSubfiles, setRenderSubfiles] = useState({
    show: false,
    id: null as any,
  });
  const [path, setPath] = useState([]);
  const [pathBack, setPathBack] = useState('');
  const [idForTable, setIdForTable] = useState(id);
  const [start, setStart] = useState('true');

  const handleOnClick = (index: any, isFolder: any) => {
    setActiveIndex(index);
    setIsFolder(isFolder);
  };

  useEffect(() => {
    if (renderSubfiles.id) {
      setIdForTable(renderSubfiles.id);
    }
  }, [renderSubfiles.id]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('directoryIdForUpload');
    };
  }, []);

  if (medicalRecords.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!medicalRecords) return null;

  return (
    <div className="flex flex-col w-full h-fit rounded-xl ">
      <TableHead
        id={id}
        parentId={idForTable}
        isFolder={isFolder}
        activeIndex={activeIndex}
        isInEditMode={isInEditMode}
        setIsInEditMode={setIsInEditMode}
        directory={medicalRecords.data.data}
        start={start}
      />
      <DocumentNavigation
        state={state}
        parentId={parentId}
        setRenderSubfiles={setRenderSubfiles}
        path={path}
        setPath={setPath}
        pathBack={pathBack}
        setStart={setStart}
      />
      <div className="flex flex-col bg-[#FFFFFF] rounded-b-xl overflow-y-auto px-4 py-4">
        {!renderSubfiles.show && (
          <Folders
            directory={medicalRecords.data.data}
            activeIndex={activeIndex}
            handleOnClick={handleOnClick}
            isInEditMode={isInEditMode}
            setIsInEditMode={setIsInEditMode}
            isFolder={isFolder}
            setIsFolder={setIsFolder}
            renderSubfiles={renderSubfiles}
            setRenderSubfiles={setRenderSubfiles}
            setParentId={setParentId}
            path={path}
            setPath={setPath}
            setStart={setStart}
            setFilesCount={setFilesCount}
          />
        )}
        {renderSubfiles.show && (
          <Subfiles
            id={renderSubfiles.id}
            isInEditMode={isInEditMode}
            setRenderSubfiles={setRenderSubfiles}
            handleOnClick={handleOnClick}
            setParentId={setParentId}
            path={path}
            setPath={setPath}
            setPathBack={setPathBack}
            setIsInEditMode={setIsInEditMode}
            setActiveIndex={setActiveIndex}
            setStart={setStart}
            activeIndex={activeIndex}
            setFilesCount={setFilesCount}
          />
        )}
      </div>
    </div>
  );
};
