import { ArchiveIcon } from '@heroicons/react/outline';
import * as React from 'react';

type TableColumn<Entry> = {
  title: string;
  field: keyof Entry;
  index?: any;
  Cell?({ entry }: { entry: Entry }): React.ReactElement | any;
};

export type TableProps<Entry> = {
  data: Entry[];
  columns: TableColumn<Entry>[];
  total?: string;
};

export const Table = <Entry extends { id: string }>({
  data,
  columns,
  total,
}: TableProps<Entry>) => {
  if (!data?.length) {
    return (
      <div className="bg-white text-gray-500 h-80 flex justify-center items-center flex-col">
        <ArchiveIcon className="h-16 w-16" />
        <h4>Rezultati nisu pronadjeni</h4>
      </div>
    );
  }
  return (
    <div className="flex flex-col table-holder max-w-full">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 max-w-full ">
              <thead className="bg-light-green font-medium mb-8" style={{ color: '#05831A' }}>
                <tr className="mb-2 max-w-full">
                  {columns.map((column, index) => (
                    <th
                      key={column.title + index}
                      scope="col"
                      className="px-2 py-3 text-left text-base font-bold text-[#05831A] tracking-[0.2px]"
                    >
                      {column.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {total && (
                  <tr className="h-[20px]">
                    <td className="absolute ml-4 text-[#4A4A4A] text-[13px]">{total}</td>
                  </tr>
                )}
                {data.map((entry, entryIndex) => (
                  <tr key={entry?.id || entryIndex} className="bg-white rounded-lg tableRounder">
                    {columns.map(({ Cell, field, title }, columnIndex) => (
                      <td
                        key={title + columnIndex}
                        className="px-6 py-2.5 whitespace-nowrap text-sm font-medium text-gray-900"
                      >
                        {Cell ? <Cell entry={entry} /> : entry[field]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
