import { useDiseases } from '../api/getSearchData';

export const AllFilterDiseasesList = ({ searchParam, setSearchParam, isPatientsPage }: any) => {
  const keyName = isPatientsPage ? 'diseases.id' : 'medicalRecord.diseases.id';
  const filterDiseases = searchParam[`${keyName}`];
  const diseasesNameArr: any = [];
  const diseasesQuery: any = useDiseases();
  if (!diseasesQuery) return null;

  if (diseasesQuery.data) {
    filterDiseases.map((id: any) => {
      diseasesQuery?.data?.data.map((data: any) => {
        if (data.id === parseInt(id)) {
          diseasesNameArr.push({ name: data.name, id: data.id });
        }
      });
    });
  }

  const allFilterDiseasesList = diseasesNameArr.map((data: any) => {
    return (
      <button
        className="bg-dark-green hover:opacity-20 text-white py-2 px-4 rounded mr-5 mb-5 text-sm"
        key={data.id}
        onClick={() => {
          removeDiseasesFromFilter(data.id);
        }}
      >
        {data.name} <span>X</span>
      </button>
    );
  });

  function removeDiseasesFromFilter(id: any) {
    const newArr = [...searchParam[`${keyName}`]];
    setSearchParam({ ...searchParam, [`${keyName}`]: newArr.filter((e) => e !== `${id}`) });
  }

  return <>{allFilterDiseasesList}</>;
};
