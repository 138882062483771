import { Fragment, useEffect, useState } from 'react';
import { Link, Routes, Route } from 'react-router-dom';

import logo from '@/assets/logo192.png';
import storage from '@/utils/storage';

import { useRemoveCalendarEvent } from '../api/useRemoveCalendarEvent';
import { useRescheduleCalendarEvent } from '../api/useRescheduleCalendarEvent';
import Appointments from '../components/Appointments';
import { ChangeModal, DeleteModal } from '../components/ConfirmationDialogs';
import Contact from '../components/Contact';
import Documents from '../components/Documents';
import Modal from '../components/Modal';
import PatientLinks from '../components/PatientLinks';
import { AppointmentProps } from '../types/index';

export const UuidPatients = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [action, setAction] = useState<string | null>(null);
  const [appointment, setAppointment] = useState<AppointmentProps | null>(null);
  const [changeAppointments, setChangeAppointments] = useState(false);
  const uuid = location.search.slice(6);
  if (uuid !== '' && uuid !== localStorage.getItem('uuid')) localStorage.setItem('uuid', uuid);
  useEffect(() => {
    return () => storage.clearToken();
  }, []);

  const {
    mutate: rescheduleCalendar,
    isLoading: isRescheduleLoading,
    isError: isRescheduleError,
    error: rescheduleError,
  } = useRescheduleCalendarEvent(setIsOpen, setChangeAppointments);

  const {
    mutate: removeCalendarEvent,
    isLoading: isDeleting,
    isError: isRemoveError,
    error: removeError,
  } = useRemoveCalendarEvent(setIsOpen, setChangeAppointments);

  const openModal = (action: 'delete' | 'change', appointment: AppointmentProps) => {
    setAppointment(appointment);
    setAction(action);
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const deleteAppointmentHandler = (e: AppointmentProps | null | undefined) => {
    removeCalendarEvent(e!.event_id);
  };
  const changeAppointmentHandler = (e: AppointmentProps | null | undefined) => {
    rescheduleCalendar(e);
  };

  return (
    <Fragment>
      <Link className="block w-[190px] mt-4 ml-1 mb-2" to={`/patient`}>
        <img src={logo} alt="Logo" className="w-full h-auto" />
      </Link>
      <div className="p-2 calendarTemplate mx-auto">
        <PatientLinks />
        <Routes>
          <Route
            path={`/`}
            element={
              <Appointments
                openModal={openModal}
                changeAppointments={changeAppointments}
                setChangeAppointments={setChangeAppointments}
              />
            }
          />
          <Route path="documents" element={<Documents />} />
          <Route path="contact" element={<Contact />} />
        </Routes>
        <Modal isOpen={isOpen} closeModal={closeModal}>
          {action === 'delete' ? (
            <DeleteModal
              appointment={appointment}
              closeModal={closeModal}
              deleteAppointmentHandler={deleteAppointmentHandler}
              isLoading={isDeleting}
              isError={isRemoveError}
              error={removeError}
            />
          ) : action === 'change' ? (
            <ChangeModal
              appointment={appointment}
              closeModal={closeModal}
              changeAppointmentHandler={changeAppointmentHandler}
              isLoading={isRescheduleLoading}
              isError={isRescheduleError}
              error={rescheduleError}
            />
          ) : null}
        </Modal>
      </div>
    </Fragment>
  );
};
