import exportIcon from '@/assets/icons/export.png';
import { axios } from '@/lib/axios';

export const ExportTable = ({ param, url }: any) => {
  const FileDownload = require('js-file-download');
  let title = 'uplate.xlsx';
  if (url === 'medical-records') title = 'pacijenti.xlsx';
  function exportTable() {
    axios({
      url: `/${url}/export${param}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      FileDownload(response, title);
    });
  }

  return (
    <>
      <button
        className="rounded bg-gray p-2.5 px-4 text-left ml-auto h-11"
        onClick={() => exportTable()}
      >
        <img className="inline-block pb-1 pr-2" src={exportIcon}></img>
        Eksportuj
      </button>
    </>
  );
};
