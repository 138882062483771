import Axios, { AxiosRequestConfig } from 'axios';

import { API_URL } from '@/config';
import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let message = error.response.data.errors
      ? error.response.data.errors[Object.keys(error.response.data.errors)[0]][0]
      : error.response.data.message;

    if (error.response.config.responseType === 'blob') {
      message = 'Fajl nije pronadjen.'
    }

    useNotificationStore.getState().addNotification({
      type: 'error',
      title: 'Greška',
      message,
    });
    if (message === 'Unauthenticated.') {
      window.localStorage.clear();
      document.location.href = "/auth/login";
    }
    console.log(message);
    return Promise.reject(error);
  }
);
